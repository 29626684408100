import React, { useState, useEffect } from "react";
import icon from "../../assets/images/lock_icon.png";
import { Form, Button, Modal, CloseButton } from 'react-bootstrap';
import './welcome.css';
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import $ from 'jquery';
import LottieLoader from '../lottie/lottieloader';
import { getDatabase, ref, set } from "firebase/database";

function MyVerticallyCenteredModal(props) {
  
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const location = useLocation();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
 
  const onDelete = (data) => {
    setDisable(true)
    const regFormData = new FormData();
    regFormData.append('user_id', location.state.user_id);

    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/delete_account`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        "X-api-key": location.state.api_key
      },
      data: regFormData

    }).then((res) => {
      setDisable(true)
      if (res.data.status === 1) {
        setTimeout(() => {
          navigate('/', { state: res.data.data })
        }, 2000);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e.response.data, 'e')
      setDisable(false)
    })
  }

  useEffect(() => {
    $(".progress-bar").animate({
      width: "14%"
    }, 2500);
  })
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter" >
          Are you sure?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You will exit out of this sign up and all your <br></br> information will be deleted.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="no_btn">NO</Button>
        <Button onClick={(e) => onDelete(e)} className="yes_btn">YES</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Welcome() {
  const [otherlogin, setotherlogin] = useState(false);
  const handleOtherClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const liveUrl = process.env.REACT_APP_FOOTER_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const location = useLocation();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [check, setCheck] = useState({
    terms: false
  })
  const { terms } = check;
  const gender = localStorage.getItem('gender');
  function handleCheckChange(event) {
    setCheck({
      ...check,
      [event.target.name]: event.target.checked,
    });
  }

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onTermsCheckSubmit = (data) => {

    const db = getDatabase();
    set(ref(db, 'MM4U/' + location.state.user_id), {
      "conversations": {

      },
      "delete_status": false,
      "fcm_token": '',
      "lastSeen": '',
      "name": location.state.name,
      "notificationStatus": true,
      "onlineStatus": true,
      "pic": '',
      "selfie_status": location.state.selfie_status,
      "gender": gender,
      "photos_slider_status": '',
    });

    setDisable(true)
    const regFormData = new FormData();
    regFormData.append('user_id', location.state.user_id);
    regFormData.append('agree_terms', 1);

    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/agree_terms`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        "X-api-key": location.state.api_key
      },
      data: regFormData

    }).then((res) => {

      if (res.data.status === 1) {
        setTimeout(() => {
          navigate('/displayname', { state: location.state })
        }, 4500);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        setDisable(false);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e.response.data, 'e')
      if (e.message == 'Request failed with status code 401') {
        setotherlogin(true)
      }
      setDisable(false)
    })
  }

  return (
    <div className="d-flex">
      <Modal show={otherlogin} size="lg" onHide={handleOtherClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => navigate('/Login')} className="up-btn">
            ok
          </Button>
        </Modal.Footer>
      </Modal>
      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}
      <div className="col-6 welcome_page "></div>
      <div className="col-lg-6 col-12 mt-5 content_login ">
        <div className="col-xs-12 col-sm-12 progress-container ">
          <div className="progress progress-striped active">
            <div className="progress-bar progress-bar-success"></div>
          </div>
        </div>
        <CloseButton onClick={() => setModalShow(true)} className="close_btn" />
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <div className="p-2">
          <div className="text-center">
            <img src={icon} />
            <h4 className="mt-4">Welcome to Redated</h4>
            <p className="sub_text mt-3">Please follow these House Rules.</p>
            <i className="fa fa-check wel_check mt-3"></i><span>Be Yourself</span>
            <p className="sub_p">Make sure your photos age and bio are true to who you are.</p>
            <i className="fa fa-check wel_check mt-4"></i><span>Stay Safe</span>
            <p className="sub_p">Don't be too quick to give out personal information. Date Safely</p>
            <i className="fa fa-check wel_check mt-4"></i><span>Play it Cool</span>
            <p className=" sub_p">Respect others and treat them as you would like to be treated.</p>
            <i className="fa fa-check wel_check mt-4"></i><span>Be Proactive</span>
            <p className=" sub_p">Always report bad behavior.</p>
            <Form onSubmit={handleSubmit(onTermsCheckSubmit)}>
              <Form.Group className="mt-3 basic_check" controlId="formBasicCheckbox">
                <input {...register("agree_terms")} type="checkbox" name='terms' checked={terms} id="checkme" onChange={handleCheckChange} />
                &nbsp;
                Agree our &nbsp; <a href={`${liveUrl}/terms_and_conditions`} className="terms_policy" target="_blank" rel="noopener noreferrer">Terms & conditions</a> &nbsp; and &nbsp; <a href={`${liveUrl}/privacy_policy`} className="terms_policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              </Form.Group>
              <Button disabled={!terms} type="submit" variant="primary" className="agree_btn w-50 mt-3" id="sendAgree" >
                I AGREE
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Welcome;
