import React, { useEffect, useState } from "react";
import Sidemenu from "../sidebar/sidemenu";
import { useNavigate, useLocation } from 'react-router-dom';
import './matchrequests.css';
import { Form, Button, Card, Modal, Carousel, Tooltip, OverlayTrigger } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import like from '../../assets/images/outlike.png';
import liked from '../../assets/images/outliked.png';
import broken from '../../assets/images/broken.png';
import male from '../../assets/images/male_mr.png';
import female from '../../assets/images/female_mr.png';
import male1 from '../../assets/images/men_s.svg';
import female1 from '../../assets/images/women_s.svg';
import shortlist from '../../assets/images/outshortlist1.png';
import shortlisted from '../../assets/images/shortlisted.svg';
import interest from '../../assets/images/interests.png';
import interest1 from '../../assets/images/outinterest.png';
import interested from '../../assets/images/interested.png';
import bgmatch from '../../assets/images/backgroundmatch.png';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import map from '../../assets/images/location.png';
import axios from "axios";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import NoData from '../../assets/images/no_data.gif';
import LottieLoader3 from '../lottie/lottieloader3';
import deleteicon from '../../assets/images/delete-icon.jpg';
import PaymentModel from "../Model/paymentModel";
import img from '../../assets/images/logo.svg'

function Matchrequests() {

    const [closeaction, setcloseaction] = useState('true');
    const close = () => {
        setcloseaction('false');
    }
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const Auth = process.env.REACT_APP_USER_TOKEN;
    const [type, setType] = useState(3)
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [matchshow, setmatchShow] = useState(false);
    const [value1, setValue1] = React.useState(0);
    const [user, setuser] = useState({ 'image': '' })
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        makePagenotTransparent();
    }
    const [modalid, setmodalid] = useState({ 'id': '' })
    const [acceptshow, setacceptshow] = useState(false)
    const [rejectshow, setrejectshow] = useState(false)
    const [value, setValue] = useState({ 'path': '', 'request': [] })
    const navigate = useNavigate();
    const [usersData, setUsersData] = useState('')
    const [display_list, setdisplay] = useState({
        about_me: "", age: "", country_name: "", gender: "",
        height: "",
        height_unit: "",
        images: [{ 'image': '' }],
        like_status: "",
        locked_status: "",
        mother_tongue: "",
        name: "",
        nationality_name: "",
        occupation_name: "",
        religion_name: "",
        report_user_list_status: "",
        request_list_id: "",
        request_status: "",
        selfie_status: "",
        shortlist_status: "",
        state_name: "",
        study_name: null,
        unique_id: "",
        user_id: "",
        weight: "",
        weight_unit: "",
        photos_slider_status: "",
        display_name: ""
    })
    const [sliderRef] = useKeenSlider({
        slides: {
            //origin: "center",
            perView: 12,
            spacing: 0,
        },
        vertical: true,
    })

    const local_storage = JSON.parse(localStorage.getItem('userDetails'));
    const gender = JSON.parse(localStorage.getItem('gender'));
    const selfie_status = JSON.parse(localStorage.getItem('selfie_status'));
    let path = 0;
    let request_id = []
    useEffect(() => {
        window.innerWidth < 768 ? setcloseaction('false') : setcloseaction('true');

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id);
        if (type != '1') {
            makePageTransparent();
            regFormData.append('type', type)
            axios({
                method: 'POST',
                url: `${baseUrl}/Profiles/received_requests`,
                headers: {
                    "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                    'X-api-key': local_storage.api_key,
                },
                data: regFormData

            }).then((res) => {
                const UserData = res.data.status
                if (UserData === 0) {
                    setUsersData(UserData);
                    makePagenotTransparent();
                }
                if (res.data.status === 1) {
                    if (res.data.data.length > 0) {
                        const proFormData = new FormData();
                        proFormData.append('user_id', local_storage.user_id);
                        proFormData.append('profile_id', res.data.data[0].user_id);
                        axios({
                            method: 'POST',
                            url: `${baseUrl}/Profiles/profile_view`,
                            headers: {
                                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                                'X-api-key': local_storage.api_key,
                            },
                            data: proFormData

                        }).then((response) => {
                            if (response.data.status === 1) {
                                setTimeout(() => {
                                    setdisplay({
                                        about_me: response.data.data.about_me,
                                        age: response.data.data.age,
                                        country_name: response.data.data.country_name,
                                        gender: response.data.data.gender,
                                        height: response.data.data.height,
                                        height_unit: response.data.data.height_unit,
                                        images: response.data.data.images,
                                        like_status: response.data.data.like_status,
                                        locked_status: response.data.data.locked_status,
                                        mother_tongue: response.data.data.mother_tongue,
                                        name: response.data.data.name,
                                        nationality_name: response.data.data.nationality_name.length > 1 ? response.data.data.nationality_name[0] + ',' + response.data.data.nationality_name[1] : response.data.data.nationality_name[0],
                                        occupation_name: response.data.data.occupation_name,
                                        religion_name: response.data.data.religion_name,
                                        report_user_list_status: response.data.data.report_user_list_status,
                                        request_list_id: response.data.data.report_list_id,
                                        request_status: response.data.data.request_status,
                                        selfie_status: response.data.data.selfie_status,
                                        shortlist_status: response.data.data.shortlist_status,
                                        state_name: response.data.data.state_name,
                                        study_name: response.data.data.study_name,
                                        unique_id: response.data.data.unique_id,
                                        user_id: response.data.data.user_id,
                                        weight: response.data.data.weight,
                                        weight_unit: response.data.data.weight_unit,
                                        photos_slider_status: response.data.data.photos_slider_status,
                                        drink: response.data.data.drink,
                                        smoke: response.data.data.smoke,
                                        diet: response.data.data.diet,
                                        display_name: response.data.data.display_name
                                    })
                                }, 1000);
                            }
                        }).catch((error) => {
                            console.log(error);
                        })
                    }
                    //Profle View API on Page Load

                    const proFormData = new FormData();
                    proFormData.append('user_id', local_storage.user_id);
                    let profile_list = []
                    path = res.data.data.length
                    if (res.data.data.length >= 1) {
                        for (let d in res.data.data) {
                            request_id.push(res.data.data[d])

                        }
                        for (let d in res.data.data) {
                            proFormData.append('profile_id', res.data.data[d].user_id)
                            axios({
                                method: 'POST',
                                url: `${baseUrl}/Profiles/profile_view`,
                                headers: {
                                    "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                                    'X-api-key': local_storage.api_key,
                                },
                                data: proFormData

                            }).then((response) => {
                                if (response.data.status === 1) {
                                    profile_list.push(response.data.data)
                                    path = response.data.path;
                                }
                            }).catch((error) => {
                                console.log(error)
                            })
                        }
                        setTimeout(() => {
                            setValue({ 'path': res.data.path, 'request': request_id });
                            makePagenotTransparent();
                        }, 1000)
                    }
                }
            }).catch((error) => {
                console.log(error);
                makePagenotTransparent();
            })
        }
    }, [type])

    function makePageTransparent() {
        var overlay = document.getElementById('overlay');
        overlay.style.display = 'block';
    }

    function makePagenotTransparent() {
        var overlay = document.getElementById('overlay');
        overlay.style.display = 'none';
    }

    const cardDisplay = event => {
        makePageTransparent();
        setcloseaction('true');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        let id = event.currentTarget.id.split('-')[1]
        const proFormData = new FormData();
        proFormData.append('user_id', local_storage.user_id);
        proFormData.append('profile_id', id)
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/profile_view`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: proFormData

        }).then((response) => {
            setTimeout(() => {
                setdisplay({
                    about_me: response.data.data.about_me,
                    age: response.data.data.age,
                    country_name: response.data.data.country_name,
                    gender: response.data.data.gender,
                    height: response.data.data.height,
                    height_unit: response.data.data.height_unit,
                    images: response.data.data.images,
                    like_status: response.data.data.like_status,
                    locked_status: response.data.data.locked_status,
                    mother_tongue: response.data.data.mother_tongue,
                    name: response.data.data.name,
                    display_name: response.data.data.display_name,
                    nationality_name: response.data.data.nationality_name,
                    occupation_name: response.data.data.occupation_name,
                    religion_name: response.data.data.religion_name,
                    report_user_list_status: response.data.data.report_user_list_status,
                    request_list_id: response.data.data.report_list_id,
                    request_status: response.data.data.request_status,
                    selfie_status: response.data.data.selfie_status,
                    shortlist_status: response.data.data.shortlist_status,
                    state_name: response.data.data.state_name,
                    study_name: response.data.data.study_name,
                    unique_id: response.data.data.unique_id,
                    user_id: response.data.data.user_id,
                    weight: response.data.data.weight,
                    weight_unit: response.data.data.weight_unit,
                    photos_slider_status: response.data.data.photos_slider_status,
                    drink: response.data.data.drink,
                    smoke: response.data.data.smoke,
                    diet: response.data.data.diet,
                })
                makePagenotTransparent();

            }, 1000);
        }).catch((error) => {
            console.log(error)
            makePagenotTransparent();

        })
    }
    const handlelike = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/user_likes`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                if (res.data.message == "Profile Liked") {
                    setdisplay({
                        about_me: display_list.about_me, age: display_list.age, country_name: display_list.country_name, gender: display_list.gender,
                        height: display_list.height,
                        height_unit: display_list.height_unit,
                        images: display_list.images,
                        like_status: '1',
                        locked_status: display_list.locked_status,
                        mother_tongue: display_list.mother_tongue,
                        name: display_list.name,
                        display_name: display_list.display_name,
                        nationality_name: display_list.nationality_name,
                        occupation_name: display_list.occupation_name,
                        religion_name: display_list.religion_name,
                        report_user_list_status: display_list.report_user_list_status,
                        request_list_id: display_list.request_list_id,
                        request_status: display_list.request_status,
                        selfie_status: display_list.selfie_status,
                        shortlist_status: display_list.shortlist_status,
                        state_name: display_list.state_name,
                        study_name: display_list.study_name,
                        unique_id: display_list.unique_id,
                        user_id: display_list.user_id,
                        weight: display_list.weight,
                        weight_unit: display_list.weight_unit,
                        photos_slider_status: display_list.photos_slider_status,
                        smoke: display_list.smoke,
                        drink: display_list.drink,
                        diet: display_list.diet
                    })


                } else {
                    setdisplay({
                        about_me: display_list.about_me, age: display_list.age, country_name: display_list.country_name, gender: display_list.gender,
                        height: display_list.height,
                        height_unit: display_list.height_unit,
                        images: display_list.images,
                        like_status: '0',
                        locked_status: display_list.locked_status,
                        mother_tongue: display_list.mother_tongue,
                        name: display_list.name,
                        display_name: display_list.display_name,
                        nationality_name: display_list.nationality_name,
                        occupation_name: display_list.occupation_name,
                        religion_name: display_list.religion_name,
                        report_user_list_status: display_list.report_user_list_status,
                        request_list_id: display_list.request_list_id,
                        request_status: display_list.request_status,
                        selfie_status: display_list.selfie_status,
                        shortlist_status: display_list.shortlist_status,
                        state_name: display_list.state_name,
                        study_name: display_list.study_name,
                        unique_id: display_list.unique_id,
                        user_id: display_list.user_id,
                        weight: display_list.weight,
                        weight_unit: display_list.weight_unit,
                        photos_slider_status: display_list.photos_slider_status,
                        smoke: display_list.smoke,
                        drink: display_list.drink,
                        diet: display_list.diet
                    })

                }
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    const handlesshortlist = event => {
        
        if (profileview.subscription_status == 0) {
            setModalShow(true);
            return false;
        }
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/shortlist`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                list_items = list_items.filter((item) => item.key !== new_id)
                if (res.data.message == "Profile Shortlisted") {
                    setdisplay({ ...display_list, shortlist_status: '1' })
                } else {
                    setdisplay({
                        about_me: display_list.about_me, age: display_list.age, country_name: display_list.country_name, gender: display_list.gender,
                        height: display_list.height,
                        height_unit: display_list.height_unit,
                        images: display_list.images,
                        like_status: display_list.like_status,
                        locked_status: display_list.locked_status,
                        mother_tongue: display_list.mother_tongue,
                        name: display_list.name,
                        display_name: display_list.display_name,
                        nationality_name: display_list.nationality_name,
                        occupation_name: display_list.occupation_name,
                        religion_name: display_list.religion_name,
                        report_user_list_status: display_list.report_user_list_status,
                        request_list_id: display_list.request_list_id,
                        request_status: display_list.request_status,
                        selfie_status: display_list.selfie_status,
                        shortlist_status: '0',
                        state_name: display_list.state_name,
                        study_name: display_list.study_name,
                        unique_id: display_list.unique_id,
                        user_id: display_list.user_id,
                        weight: display_list.weight,
                        weight_unit: display_list.weight_unit,
                        photos_slider_status: display_list.photos_slider_status
                    })
                }
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    var today = new Date()

    let todaystring = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`

    function timeDiffCalc(date, time) {
        let d = date.split('-')
        let t = time.split(':')
        let date1 = `${d[2]}-${d[1]}-${d[0]} ${t[0]}:${t[1]}:${t[2]}`
        let d1 = new Date(date1)
        let d2 = new Date(todaystring)

        let diffInMilliSeconds = Math.abs(d1 - d2) / 1000;
        const days = Math.floor(diffInMilliSeconds / 86400);
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        let result;
        if (days != 0) {
            if (days > 2) {
                result = date
            }
            else if (days != 1) {
                result = days + ' days ago'
            } else {
                result = days + ' day ago'
            }
        } else if (hours != 0) {
            if (hours != 1) {
                result = hours + ' hours ago'
            }
            else {
                result = hours + ' hour ago'
            }
        } else {
            if (minutes != 1) {
                result = minutes + ' minutes ago'
            } else {
                result = minutes + ' minute ago'
            }
        }

        return result;
    }
    const [modalShow, setModalShow] = React.useState(false);
    const profileview = JSON.parse(localStorage.getItem('userDetails'));
    const handleinterest = event => {
        if (profileview.subscription_status == 0) {
            setModalShow(true);
            return false;
        }
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/send_request`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                list_items = list_items.filter((item) => item.key !== new_id)
                document.getElementById(`${new_id}`).classList.add("display")
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    const acceptinterestbutton = event => {
        if (profileview.subscription_status == 0) {
            setModalShow(true);
            return false;
        }
        let id = event.currentTarget.id.split('-')
        let profile_id = id[1]
        setmodalid({ 'id': profile_id });
        setacceptshow(true);
    }
    const rejectbutton = event => {
        if (profileview.subscription_status == 0) {
            setModalShow(true);
            return false;
        }
        let id = event.currentTarget.id.split('-')
        let profile_id = id[1]
        setmodalid({ 'id': profile_id })
        setrejectshow(true)
    }
    const acceptinterest = event => {
        setcloseaction('false');
        if (profileview.subscription_status == 0) {
            setModalShow(true);
            return false;
        }
        let id = event.currentTarget.id.split('-')
        let profile_id = id[1]

        let request_list_id = ''
        for (let i in value.request) {
            if (value.request[i].user_id == profile_id) {
                request_list_id = value.request[i].request_list_id
            }
        }

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', profile_id)
        regFormData.append('request_list_id', request_list_id)
        regFormData.append('status', 1)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/accept_decline`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                document.getElementById(`${profile_id}`).classList.add('display');
                setacceptshow(false);
                const regFormData1 = new FormData();
                regFormData1.append('user_id', local_storage.user_id);
                regFormData1.append('profile_id', profile_id);
                regFormData1.append('request_list_id', request_list_id);
                regFormData1.append('min', 0);
                regFormData1.append('max', value1);
                axios({
                    method: 'POST',
                    url: `${baseUrl}/Profiles/its_match`,
                    headers: {
                        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                        'X-api-key': local_storage.api_key,
                    },
                    data: regFormData1
                }).then((res) => {
                }).catch((e) => {
                    console.log(e);
                })
            }
            Toastify({
                text: res.data.message,
                duration: 2000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                style: {
                    background: "linear-gradient(to right, #00b09b, #96c93d)",
                },
                onClick: function () { }
            }).showToast();
            setmatchShow(true)
        }).catch((error) => {
            console.log(error)
        })
        const userFormData = new FormData();
        userFormData.append('user_id', local_storage.user_id);
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/user_profile`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: userFormData
        }).then((res) => {
            if (res.data.status === 1) {

                if (res.data.data.selfie_status === '1') {
                    setuser({ 'image': `${res.data.path}/${res.data.data.selfie_pic}` })
                } else {
                    setuser({ 'image': `${res.data.path}/${res.data.data.selfie_pic}` })
                }
            }
        }).catch((e) => {
            console.log(e)
        })
        setTimeout(() => {
            setmatchShow(true)
        }, 1000);
    }
   

    const handleBroke = event => {
        if (profileview.subscription_status == 0) {
            setModalShow(true);
            return false;
        }
        let id = event.currentTarget.id.split('-')
        let profile_id = id[1]
        setmodalid({ 'id': profile_id })
        let request_list_id = ''
        for (let i in value.request) {
            if (value.request[i].user_id == profile_id) {
                request_list_id = value.request[i].request_list_id
            }
        }

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', profile_id)
        regFormData.append('request_list_id', request_list_id)
        regFormData.append('status', 2)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/accept_decline`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                let l_items = value.request.filter((item) => item.user_id !== profile_id)
                setValue({ 'path': value.path, 'request': l_items })
                setrejectshow(false)
            }
            Toastify({
                text: res.data.message,
                duration: 2000,
                newWindow: true,
                close: true,
                gravity: "top", // `top` or `bottom`
                position: "right", // `left`, `center` or `right`
                stopOnFocus: true, // Prevents dismissing of toast on hover
                style: {
                    background: "linear-gradient(to right, #00b09b, #96c93d)",
                },
                onClick: function () { } // Callback after click
            }).showToast();
        }).catch((error) => {
            console.log(error)
        })
    }
    let list_items = value.request.map((item) =>
        <div className="card-button" id={`${item.user_id}`}>

            <div className="keen-slider__slide number-slide1 d-flex flex-column justify-content-end" style={{ backgroundImage: `url(${item.selfie_status == '1' ? value.path + '/' + item.image : item.gender == '1' ? male : female})`, backgroundRepeat: "no-repeat", backgroundSize: 'contain', borderRadius: '10px', maxWidth: '400px', height: '50vh', margin: '10px', backgroundPosition: 'center', cursor: 'pointer' }} onClick={cardDisplay} id={`info-${item.user_id}`} >
                <div className="image-block">

                    <div className="slider-bottom-mr d-flex flex-column justify-content-end">
                        <div>
                            <h5 style={{ color: 'white' }}>{item.name}, {item.age} </h5>
                            <div className="d-flex justify-content-between align-items-center">  <div> <h5 style={{ color: 'white', fontSize: 16 }}><img src={map} className="px-1" />{item.state_name != '' ? item.state_name + ',' : ''}  {item.country_name}.</h5></div>
                                {type == '1' ? <div className="near_by">{item.distance_in_km} kms away</div> : ''} {type == '3' ? <div className="near_by_recent">{timeDiffCalc(item.date, item.time)}</div> : ''}</div>
                        </div>
                    </div>

                    <div className="w-100 d-flex my-0 mr_icons">
                        <OverlayTrigger overlay={<Tooltip style={{ backgroundColor: "#fff", borderRadius: "5px" }} id={`broke-${item.user_id}`} >{"Reject"}</Tooltip>}>
                            <button className="action-buttons" id={`broke-${item.user_id}`} onClick={rejectbutton}>
                                <img src={broken} className="mx-2" />
                            </button>
                        </OverlayTrigger>

                        <OverlayTrigger overlay={<Tooltip style={{ backgroundColor: "#fff", borderRadius: "5px" }} id={`broke-${item.user_id}`} >{"Accept"}</Tooltip>}>
                            <button className="action-buttons" id={`interest-${item.user_id}`} onClick={acceptinterestbutton}><img src={interest} className="mx-2" /></button>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
        </div>
    )
    const handleselect = event => {
        if (event.target.value == '1') {
            handleShow();
        }
        if (event.target.value !== 'Cancel') {
            setType(parseInt(event.target.value));
        }
    }
    const handleDistance = () => {
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id);
        regFormData.append('type', 1);
        regFormData.append('min_distance', 0);
        regFormData.append('max_distance', value1);
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/received_requests`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                makePagenotTransparent();
                const proFormData = new FormData();
                proFormData.append('user_id', local_storage.user_id);
                request_id = []
                path = res.data.data.length
                if (res.data.data.length >= 1) {
                    for (let d in res.data.data) {
                        request_id.push(res.data.data[d])
                    }
                    setTimeout(() => {
                        setValue({ 'path': res.data.path, 'request': request_id })
                    }, 1000)
                }
            }
        }).catch((error) => {
            console.log(error);
            makePagenotTransparent();
        })
        setShow(false);
        
    }
    const matchclose = () => {
        setmatchShow(false)
    }
    return (
        <div className="d-lg-flex d-block" >
            <>
                <PaymentModel
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="nearby_head">Maximum Distance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <p className="nearby_km">{value1}KM</p>
                                <RangeSlider
                                    min={0}
                                    max={1000}
                                    value={value1}
                                    onChange={e => setValue1(e.target.value)}
                                    fillStyle={(index) => {
                                        if (index === 0) {
                                            return { background: 'dodgerblue' }
                                        }
                                    }}
                                />
                                <div className="d-flex justify-content-between">
                                    <div>1KM</div> <div>1000KM</div></div>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose} className="near_cancel">
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleDistance} className="near_ok">
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            {/* matchshow */}
            <Modal show={matchshow} onHide={matchclose}>
                <Modal.Footer className="match_body">

                    <div className="text-center" style={{ backgroundImage: `url(${bgmatch})`, backgroundSize: 'cover' }}>
                        <h5 className="my-5 py-5" style={{ fontWeight: 600, fontSize: 24 }}>It's a Match!</h5>
                        <div className="d-flex justify-content-center align-items-center my-5">
                            <img src={`${selfie_status == '1' ? user.image : local_storage.gender == '1' ? male : female}`} style={{ width: '100px', height: '100px', borderRadius: '50px', objectFit: 'contain' }} className="match1" />
                            <img src={interested} className="match2" />
                            <img src={`${display_list.selfie_status == '1' ? value.path + '/' + display_list.images[0].image : display_list.gender == '1' ? male : female}`} style={{ width: '100px', height: '100px', borderRadius: '50px', objectFit: 'contain' }} className="match3" />
                        </div>
                    </div>
                </Modal.Footer>
                <Modal.Footer>
                    <div>
                        <Button className="my-2" style={{ backgroundColor: '#E6368B', color: 'white', border: '0px', width: '100%' }} onClick={() => navigate('/matches')} >
                            SEND MESSAGE
                        </Button>
                        <Button variant="secondary" className="my-2 w-100" onClick={() => navigate('/matchedprofiles')}>
                            FIND MORE PROFILES
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            <div className="col-3 page_left" style={{ width: 'auto' }}>
                <Sidemenu />
            </div>
            <div className="col-12 col-lg-9 d-flex flex-column match-request-page page_right" style={{ height: "100vh", overflowY: 'scroll' }}>
                <div className="d-flex flex-row bg-white top-bar shadow">
                <img src={img} className="w-50 site_logo d-block m-auto inner-logo"/>
                    {/* <Form.Select className="type-select" onMouseOutCapture={handleselect}>
                        <option value='3'>Recent</option>
                        <option value='1'>Near by me</option>
                        <option value='2'>Age Range</option>
                        <option>Cancel</option>
                    </Form.Select> */}
                </div>

                <div className="d-flex p-0 m-0">
                    <div className="user_info col-12 my-3 px-5">
                        {usersData == '0' ? <div className="d-flex justify-content-center align-items-center h-50vh" style={{ height: '60vh' }}><img src={NoData} /></div> : ''}

                        {value.request.length > 0 &&
                            <div ref={sliderRef} className="keen-slider" >
                                <ul>{list_items}</ul>
                            </div>
                        }
                    </div>

                    {display_list.user_id !== "" ?
                        <div id={`item-${display_list.user_id}`}>
                            {closeaction == 'true' ?
                                <Card className="right-card profile_div" id="right-card">
                                    {display_list.images.length > 1 ?
                                        <div style={{ backgroundColor: '#c4c4c4' }}>
                                            <span style={{ visibility: 'hidden' }}> aa </span>
                                            <span className="close_button float-end px-2" onClick={close} >
                                                <img src={deleteicon} className="close-button" alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                                            </span>
                                            <div>
                                                <Carousel>
                                                    {display_list.images.map((particular_image, imageindex) =>
                                                        <Carousel.Item key={imageindex} style={{ width: '400px', height: '300px', overflow: 'hidden', borderRadius: '0px', backgroundColor: '#c4c4c4' }}>
                                                            <Card.Img variant="top" src={`${display_list.selfie_status == '1' ? value.path + '/' + display_list.images[imageindex].image : display_list.gender == '1' ? male1 : female1}`} style={{ width: '100%', height: '100%', objectFit: 'contain', top: '0' }} />
                                                        </Carousel.Item>
                                                    )}
                                                </Carousel>
                                            </div>
                                        </div>
                                        :
                                        <div style={{ color: "#E6368B", backgroundColor: '#c4c4c4' }}>
                                            <span className="close_button float-end px-2" onClick={close} >
                                                <img src={deleteicon} className="close-button" alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                                            </span>
                                            <Card.Img variant="top" src={`${display_list.selfie_status == '1' && display_list.images.length == '1' ? value.path + '/' + display_list.images[0].image : display_list.gender == '1' ? male1 : female1}`} style={{ width: '400px', height: '300px', top: '0', backgroundColor: '#c4c4c4', objectFit: 'contain' }} />
                                        </div>
                                    }
                                    <Card.Body>
                                        <Card.Title><p style={{ fontWeight: 500 }}>{display_list.display_name}, {display_list.age}, {display_list.gender == 1 ? "Male" : "Female"} <span style={{ color: '#E6368B' }}>(#{display_list.unique_id})</span></p>
                                            <p><img src={map} className="px-1" />{display_list.state_name ? `${display_list.state_name},` : ''}{display_list.country_name}.</p>
                                            {display_list.request_status == '4' ? <span style={{ color: '#E6368B' }}>{display_list.display_name} sent you a request</span> : ''}

                                            <p style={{ fontWeight: 500 }} className="mt-2">About me</p>
                                            <p>{display_list.about_me}</p>
                                        </Card.Title>

                                        <Card.Text>
                                            <div className="profile_data">
                                                <div><span>Languages</span></div>
                                                <div><span>{display_list.mother_tongue}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Nationality</span></div>
                                                <div><span>{display_list.nationality_name}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Country</span></div>
                                                <div><span>{display_list.country_name}</span></div>
                                            </div>
                                            {display_list.state_name != '' ? <div className="profile_data">
                                                <div><span>State</span></div>
                                                <div><span>{display_list.state_name}</span></div>
                                            </div> : ''}
                                            <div className="profile_data">
                                                <div><span>Occupation</span></div>
                                                <div><span>{display_list.occupation_name}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Height</span></div>
                                                <div><span>{display_list.height} {display_list.height_unit}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Weight</span></div>
                                                <div><span>{display_list.weight}</span></div>
                                            </div>
                                            {display_list.study_name &&
                                                <div className="profile_data">
                                                    <div><span>Education</span></div>
                                                    <div><span>{display_list.study_name}</span></div>
                                                </div>}
                                            <div className="profile_data">
                                                <div><span>Religion</span></div>
                                                <div><span>{display_list.religion_name}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Smoke</span></div>
                                                <div><span>{display_list.smoke}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Drink</span></div>
                                                <div><span>{display_list.drink}</span></div>
                                            </div>
                                            <div className="profile_data">
                                                <div><span>Diet</span></div>
                                                <div><span>{display_list.diet}</span></div>
                                            </div>
                                            <hr className="m-0 p-0" style={{ color: "#E6368B", backgroundColor: "#E6368B" }}></hr>
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="m-auto">
                                        <OverlayTrigger overlay={<Tooltip>{"Accept"}</Tooltip>}>
                                            <button className="action-buttons" id={`dinterest-${display_list.user_id}`} onClick={acceptinterestbutton}><img src={display_list.request_status == '4' ? interested : interest} className="mx-2" /></button>
                                        </OverlayTrigger>
                                        <OverlayTrigger overlay={<Tooltip>{"Like"}</Tooltip>}>
                                            <button className="action-buttons" id={`dlike-${display_list.user_id}`} onClick={handlelike}><img id={`dlikes-${display_list.user_id}`} src={display_list.like_status == '0' ? like : liked} className="mx-2" /></button>
                                        </OverlayTrigger>
                                        <OverlayTrigger overlay={<Tooltip>{"Shortlist"}</Tooltip>}>
                                            <button className="action-buttons" id={`dshortlist-${display_list.user_id}`} onClick={handlesshortlist}><img src={display_list.shortlist_status == '0' ? shortlist : shortlisted} className="mx-2" /></button>
                                        </OverlayTrigger>
                                    </Card.Footer>
                                </Card> : ""}
                        </div> : ''}
                    <Modal show={rejectshow} size="lg" animation={false} aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Body className="text-center">Do you want to reject the request</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setrejectshow(false)}>
                                No
                            </Button>
                            <Button id={`br-${modalid.id}`} onClick={handleBroke} className="up-btn">
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={acceptshow} size="lg" animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body className="text-center">Are you sure want to Accept the request</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setacceptshow(false)}>
                                No
                            </Button>
                            <Button id={`int-${modalid.id}`} onClick={acceptinterest} className="up-btn">
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
            <div id="overlay">
                <div className='AddloadingImage'>
                    <LottieLoader3 />
                </div>
            </div>
        </div>
    )
}

export default Matchrequests;