import React, { useState } from "react";
import icon from "../../assets/images/lock_icon.png";
import { Form, FormControl, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import LottieLoader from '../lottie/lottieloader';

function Emailverify() {
  const [otherlogin, setotherlogin] = useState(false);
  const handleOtherClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));
  const newmail = JSON.parse(localStorage.getItem('newmail'));
  const navigate = useNavigate();
  const location = useLocation();
  const [disable, setDisable] = useState(false);
  const [editdisplay, setEditDisplay] = useState('true');

  const submitEmalVerify = (e) => {
    setDisable(true)
    e.preventDefault();
    const regFormData = new FormData();
    let key;
    if (location.state !== null) {
      regFormData.append('user_id', location.state.user_id);
      key = location.state.api_key;
    } else {
      regFormData.append('user_id', local_storage.user_id)
      key = local_storage.api_key;
    } 


    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/verify_email`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key,
      },
      data: regFormData

    }).then((res) => {

      if (res.data.status === 1 && res.data.email_status === '1') {

        if (location.state == null && local_storage.selfie_screen_status == '1') {
          navigate('/Login')
        } else {
          setTimeout(() => {
            navigate('/security', { state: location.state })
          }, 2500);
        }

        Toastify({
          text: res.data.message,
          duration: 2500,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        setDisable(false)
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e.response, 'e')
      if (e.message == 'Request failed with status code 401') {
        setotherlogin(true)
      }
      setDisable(false)
    })
  };

  const Editfunction = () => {
    setEditDisplay('false');
  }

  let emaildata;
  if (location.state && location.state.email !== undefined && location.state.email !== null) {
    emaildata = location.state.email;
  } else {
    emaildata = "";
  }
  const [email, setEmail] = useState(emaildata);


  const emailhandler = (e) => {
    setEmail(e.target.value);
  }

  const resendEmail = (e) => {
    e.preventDefault()
    const resendFormData = new FormData();
    let key;
    if (location.state !== null) {
      resendFormData.append('user_id', location.state.user_id);
      key = location.state.api_key;
    } else {
      resendFormData.append('user_id', local_storage.user_id)
      key = local_storage.api_key;
    }
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/resend_verify_link`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key,
      },
      data: resendFormData
    }).then((res) => {
      Toastify({
        text: res.data.message,
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: "top", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: "linear-gradient(to right, #00b09b, #96c93d)",
        },
        onClick: function () { } // Callback after click
      }).showToast();
    }).catch((e) => {
      console.log(e.data.data, 'REEEE')
    })
  }

  const newEmail = (data) => {
    setEditDisplay('true');

    const regFormData = new FormData();
    regFormData.append('user_id', location.state.user_id);
    regFormData.append('email', email);

    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/change_email`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': location.state.api_key
      },
      data: regFormData
    }).then((res) => {
      if (res.data.status === 1) {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();

      }

    }).catch((e) => {
      console.log(e, 'e')
    })
  }
  return (

    <div className="d-lg-flex d-block">
      <Modal show={otherlogin} size="lg" onHide={handleOtherClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => navigate('/Login')} className="up-btn">
            ok
          </Button>
        </Modal.Footer>
      </Modal>
      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}
      <div className="col-lg-6 col-12 emailverify_page"></div>
      <div className="col-lg-6 col-12 mt-5 content">

        <div class="p-2 text-center">
          <img src={icon} />

          {editdisplay == "true" ?
            <div>
              
              <h6 className="mt-5">Verify your email address</h6>
              
              <h6 className="mt-4">{location.state !== null ? email : newmail}
                <span style={{ color: "#E6368B", fontWeight: "500", fontSize: "11px", cursor: "pointer" }} onClick={Editfunction}> Edit  </span>
              </h6>
             
              <div>
                <p className="mt-4 w-75 m-auto" style={{ fontSize: '14px' }}>Please verify your email and click on the verification link for validation.
                  Check your spam folder, if email is not found. Once verified you can Continue.
                </p>
              </div>
              <Form className="mt-3">
                <Button onClick={(e) => submitEmalVerify(e)} variant="primary" className="d-block m-auto mt-3 w-50 btn_disabled">
                  CONTINUE
                </Button>
                <Button variant="link" className="mt-4 emailver_btn d-block m-auto resend_btn" onClick={(e) => resendEmail(e)}>Resend verification link</Button>
              </Form>
            </div> :
            <div className="col-lg-6 col-10 mt-5 content m-auto">
              <h6 className="mt-5">Enter New Email</h6>
              <FormControl className="form-control mt-4" id="newemail" value={email} onChange={emailhandler} />
              <Button variant="primary" className="reset_btn w-lg-50 w-auto" onClick={newEmail}>
                CHANGE EMAIL</Button>
            </div>
          }
        </div>
      </div>
    </div>
  )
}


export default Emailverify;