import React, { useState, useEffect } from "react";
import icon from "../../assets/images/lock_icon.png";
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { useForm } from "react-hook-form";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import LottieLoader from '../lottie/lottieloader';

function Security() {
  const [otherlogin, setotherlogin] = useState(false);
  const handleOtherClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  //console.log(location.state,'QUESTIOn')
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const [disable, setDisable] = useState(false);
  const [count, setCount] = React.useState(0);
  const [questions, setQuestons] = useState([]);
  const [userselectedQ, setUserselectedQ] = useState(1);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));

  useEffect(() => {
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/security_questions`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': Auth,
      }
    }).then((res) => {
      setQuestons(res.data.data)
    }).catch((e) => {
      console.log(e)
    })
  }, [])
  const onQuestionSubmit = (data) => {
    if (!data.answers || data.answers.trim() === "") {
      Toastify({
        text: "Please enter your security question answer",
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
          background: "linear-gradient(to right, #ff5f6d, #ffc371)",
        },
      }).showToast();
      return;
    }
  
    setDisable(true);
    const regFormData = new FormData();
    let key;
    if (location.state !== null) {
      regFormData.append('user_id', location.state.user_id);
      key = location.state.api_key;
    } else {
      regFormData.append('user_id', local_storage.user_id);
      key = local_storage.api_key;
    }
    regFormData.append('question_id', userselectedQ);
    regFormData.append('answer', data.answers);
    
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/question_submit`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key,
      },
      data: regFormData
    })
      .then((res) => {
        if (res.data.status === 1) {
          if (location.state == null && local_storage.selfie_screen_status == '1') {
            navigate('/settings');
          } else {
            setTimeout(() => {
              navigate('/welcome', { state: location.state });
            }, 2500);
          }
          Toastify({
            text: res.data.message,
            duration: 2000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true,
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            },
          }).showToast();
        } else {
          setDisable(false);
          Toastify({
            text: res.data.message,
            duration: 2000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true,
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            },
          }).showToast();
        }
      })
      .catch((e) => {
        console.log(e.response, 'e');
        if (e.message === 'Request failed with status code 401') {
          setotherlogin(true);
        }
        setDisable(false);
      });
  };
  
  function handlSelect(event) {
    setUserselectedQ(event.target.value)
  }
  const handleKeyPress = (evt) => {
    var theEvent = evt || window.event;
    if (count >= 40) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  const aboutInput = (e) => {
    setCount(e.target.value.length)
  }
  return (

    <div className="d-lg-flex d-block">
      <Modal show={otherlogin} size="lg" onHide={handleOtherClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => navigate('/Login')} className="up-btn">
            ok
          </Button>
        </Modal.Footer>
      </Modal>
      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}
      <div className="col-lg-6 col-12 security_page"></div>
      <div className="col-lg-6 col-12 mt-5 content_login">

        <div className="p-2 text-center">
          <img src={icon} />
          <h6 className="mt-5">Security Question</h6>
          <div>
            <p>This if you forget your password, your security question helps establish that own your account.</p>
          </div>
        </div>

        <h3 className="text-start">Question</h3>
        <Form className="mt-3" onSubmit={handleSubmit(onQuestionSubmit)}>
          <Form.Select aria-label="Default select example" onChange={handlSelect}>
            {questions.map((data, i) => {
              return (
                <option className={`${userselectedQ === data.question_id ? 'yes' : 'noo'}`} key={i} value={data.question_id} selected>{data.question}</option>
              )
            })}
          </Form.Select>
          <Form.Control {...register("answers")} onChange={aboutInput}
            onKeyPress={handleKeyPress} type="text" className="form-control mt-4" placeholder="answer" />
          <Button variant="dark" type="submit" className="d-block m-auto mt-3 w-25 btn_disabled">
            SUBMIT
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default Security;