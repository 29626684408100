import React, { useState, useEffect } from "react";
import icon from "../../assets/images/lock_icon.png";
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import OtpInput from "react-otp-input";
import { useNavigate, useLocation } from 'react-router-dom';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import $ from 'jquery';
import LottieLoader from '../lottie/lottieloader';
import "../login/login.css";

function Code() {

  const [show, setShow] = useState(false)
  const [disable, setDisable] = useState(false);
  const [resetdisable, setResetdisable] = useState({ 'display': 'block' });
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  // Add this at the top after const location
  
  const location = useLocation();
  //console.log(location.state, "location");
  const [userId, setUserId] = useState(location.state || null);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [otp, setOtp] = useState(0);
  const [total, setTotal] = useState([]);
  const otpNum = [];
  const [disabled, setDisabled] = useState(false);
  const [message, setmessage] = useState({ 'cmessage': '', 'color': '' })
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);

  useEffect(() => {
    if (location.state) {
      setUserId(location.state);
    }
  }, [location.state]);

  const handleChange = (e) => {
    let otp = e
    setOtp(e);
    otpNum.push(e)
    setTotal(otpNum)
    if (otp.length === 6) {
      const regFormData = new FormData();
      regFormData.append('user_id', userId);
      regFormData.append('otp', otp);
      regFormData.append('type', 'forgot');
      axios({
        method: 'POST',
        url: `${baseUrl}/Registration/verify_otp`,
        headers: {
          "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
          'X-api-key': Auth,
        },
        data: regFormData
      }).then((res) => {
        if (res.data.status === 1 && res.data.forgot_verify_status === '1') {
          setResetdisable({ 'display': 'none' })
          setShow(true)
          navigate('/newpassword', { state: res.data.forgot_user_id })
          Toastify({
            text: res.data.message,
            duration: 2000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            },
            onClick: function () { } // Callback after click
          }).showToast();

        } else {
          Toastify({
            text: res.data.message,
            duration: 2000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            },
            onClick: function () { } // Callback after click
          }).showToast();
        }
      }).catch((e) => {
        console.log(e, 'e')
      })
    }
  };

  const handlePasswordChange = (e) => {
    if (checkStrength(e.target.value) == false) {
      $('#sign-up').attr('disabled', true);
    }
  }

  const checkStrength = (password) => {
    var strength = 0;
    //If password contains both lower and uppercase characters, increase strength value.
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      strength += 1;
      $('.active_val').addClass('text-success pass_val');
      $('.low-upper-case i').removeClass('fa-file_text').addClass('');
      $('#popover-password-top').addClass('hide');

    } else {
      $('.active_val').removeClass('text-success pass_val');
      $('.low-upper-case i').addClass('fa-file_text_fail').removeClass('');
      $('#popover-password-top').removeClass('hide');
    }

    //If it has numbers and characters, increase strength value.
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
      strength += 1;
      $('.active_val1').addClass('text-success pass_val');
      $('.one-number i').removeClass('fa-file_text').addClass('k');
      $('#popover-password-top').addClass('hide');

    } else {
      $('.active_val1').removeClass('text-success pass_val');
      $('.one-number i').addClass('fa-file_text').removeClass('');
      $('#popover-password-top').removeClass('hide');
    }

    //If it has one special character, increase strength value.
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      strength += 1;
      $('.active_val2').addClass('text-success pass_val');
      $('.one-special-char i').removeClass('fa-file_text').addClass('');
      $('#popover-password-top').addClass('hide');

    } else {
      $('.active_val2').removeClass('text-success pass_val');
      $('.one-special-char i').addClass('fa-file_text').removeClass('');
      $('#popover-password-top').removeClass('hide');
    }

    if (password.length > 7) {
      strength += 1;
      $('.active_val3').addClass('text-success pass_val');
      $('.eight-character i').removeClass('fa-file_text').addClass('');
      $('#popover-password-top').addClass('hide');

    } else {
      $('.active_val3').removeClass('text-success pass_val');
      $('.eight-character i').addClass('fa-file_text').removeClass('');
      $('#popover-password-top').removeClass('hide');
    }

    if (strength < 2) {
      $('#result').removeClass()
      $('#password-strength').addClass('progress-bar-danger');

      $('#result').addClass('text-danger').text('Very Week');
      $('#password-strength').css('width', '10%');
    } else if (strength == 2) {
      $('#result').addClass('good');
      $('#password-strength').removeClass('progress-bar-danger');
      $('#password-strength').addClass('progress-bar-warning');
      $('#result').addClass('text-warning').text('Week')
      $('#password-strength').css('width', '60%');
      return 'Week'
    } else if (strength == 4) {
      $('#result').removeClass()
      $('#result').addClass('strong');
      $('#password-strength').removeClass('progress-bar-warning');
      $('#password-strength').addClass('progress-bar-success');
      $('#result').addClass('text-success pass_val').text('Strength');
      $('#password-strength').css('width', '100%');
      return 'Strong'
    }
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const togglePassword1 = () => {
    setPasswordShown1(!passwordShown1);
  };


  const onQuestionSubmit = (data) => {
    setDisable(true)

    const regFormData = new FormData();
    regFormData.append('user_id', userId);
    regFormData.append('new_password', data.password);
    regFormData.append('confirm_password', data.confirmPassword);

    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/update_password`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': Auth,
      },
      data: regFormData

    }).then((res) => {
      setDisable(false)

      if (res.data.status === 1) {
        setTimeout(() => {
          navigate('/login', { state: res.data.forgot_user_id })
        }, 2000);

        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e, 'e')
      setDisable(false)
    })
  }
  const resendOtp = (e) => {
    e.preventDefault()
    setOtp(0);
    const resendFormData = new FormData();
    resendFormData.append('user_id', userId);
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/resend_otp`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': Auth,
      },
      data: resendFormData
    }).then((res) => {
      Toastify({
        text: res.data.message,
        duration: 2000,
        close: true,
        gravity: "top", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: "linear-gradient(to right, #00b09b, #96c93d)",
        },
        onClick: function () { } // Callback after click
      }).showToast();
      setmessage({ 'cmessage': '', 'color': 'green' })
      disAble();
    }).catch((e) => {
      console.log(e.data.data, 'REEEE')
    })
  }
  useEffect(() => {
    $(document).ready(function () {
      $('#password').keyup(function () {
        var password = $('#password').val();
        if (checkStrength(password) == false) {
          $('#sign-up').attr('disabled', true);
        }
      });
      $('#confirm-password').blur(function () {
        if ($('#password').val() !== $('#confirm-password').val()) {
          $('#popover-cpassword').removeClass('hide');
          $('#sign-up').attr('disabled', true);
        } else {
          $('#popover-cpassword').addClass('hide');
        }
      });
    });
  }, []);
  const disAble = () => {
    document.getElementById('resend').disabled = true;
    setTimeout(function () {
      document.getElementById('resend').disabled = false;
    }, 30000)
  }

  return (

    <div className="d-flex">
      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}
      <div className="col-6 security_page"></div>
      <div className="col-6 mt-5 content_login">
        <div className="p-2 text-center">
          <img src={icon} className="mb-5" />
          <p>My Code</p>
          <div className="otp_input col-6 m-auto">
            <OtpInput
              inputStyle={{
                width: '2rem',
                height: '2rem',
                margin: '10px 10px',
                fontSize: '1rem',
                border: 'none',
                borderBottom: '1px solid',
              }}
              isInputNum='true'
              maxLength='6'
              numInputs={6}
              value={otp}
              onChange={handleChange}
              separator={<span></span>}
            />
          </div>
          <p style={{ color: `${message.color}` }}>{message.cmessage}</p>
        </div>
        {show ? <div className="col-10 m-auto mt-4">
          <Form onSubmit={handleSubmit(onQuestionSubmit)}>
            <Form.Group className="mb-1" controlId="formBasicPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control {...register("password", { required: true, minLength: 8, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/ })} type={passwordShown ? "text" : "password"} className="pass_field" onChange={handlePasswordChange} />
              <span className="passtext-1" onClick={togglePassword}>{passwordShown ? "hide" : "show"}</span>
              <span className='error_msg'> {errors.password?.type === 'required' && "Please enter new password"}</span>
              <span className='error_msg'> {errors.password?.type === 'minLength' && "Password should be 8 characters with atleast 1 upper, 1 lower, 1 numeric and 1 special character"}</span>
              <span className='error_msg'> {errors.password?.type === 'pattern' && "Password should be 8 characters with atleast 1 upper, 1 lower, 1 numeric and 1 special character"}</span>
              <ul className="list-unstyled password_val">
                <li className="active_val3"><span className="eight-character"><i className="fa fa-file_text" aria-hidden="true"></i></span>&nbsp; 8+ Character</li>
                <li className="active_val2"><span className="one-special-char"><i className="fa fa-file_text" aria-hidden="true"></i></span> &nbsp;# Special </li>
                <li className="active_val"><span className="low-upper-case"><i className="fa fa-file_text" aria-hidden="true"></i></span>&nbsp; Aa Alphabet</li>
                <li className="active_val1"><span className="one-number"><i className="fa fa-file_text" aria-hidden="true"></i></span> &nbsp;1 number</li>
              </ul>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control {...register("confirmPassword")} type={passwordShown1 ? "text" : "password"} className="pass_field" />
              <span className="passtext-2" onClick={togglePassword1}>{passwordShown1 ? "hide" : "show"}</span>
              <span className='error_msg'> {errors.confirmPassword?.type === 'minLength' && "Password should be 8 characters with atleast 1 upper, 1 lower, 1 numeric and 1 special character"}</span>
              <span className='error_msg'> {errors.confirmPassword?.type === 'pattern' && "Password should be 8 characters with atleast 1 upper, 1 lower, 1 numeric and 1 special character"}</span>
            </Form.Group>
            <button type="submit" className="btn btn-dark mt-3 btn_disabled m-auto d-block ">SUBMIT</button>
          </Form>
        </div> : ''}
        <br></br>
        <Button variant="light" onClick={(e) => resendOtp(e)} style={{ display: `${resetdisable.display}` }} className="m-auto resend_btn" id='resend'>RESEND CODE</Button>
      </div>
    </div>
  )
}

export default Code;
