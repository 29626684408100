import React, { useState, useEffect } from "react";
import Sidemenu from "../sidebar/sidemenu";
import { useLocation } from 'react-router-dom';
import './chat.css';
import { Card } from 'react-bootstrap';
import CardHeader from "react-bootstrap/esm/CardHeader";
import Newmsg from './Newchat';
import './messaging_init_in_sw';
import { ref, onValue, off } from "firebase/database";
import { db } from './firebase';
import axios from "axios";
import NoData from '../../assets/images/no_data.gif';
import deleteicon from '../../assets/images/delete-icon.jpg';
//import {auth} from './firebase.js';

function Chat() {
    const [show, setShow] = useState(true);
    let [useritemid, setUseritemid] = useState('');
    const [chatlist, setchatlist] = useState([]);
    const [otheruid, setotheruid] = useState('');
    //const [user] = useAuthState(auth);
    const local_storage = JSON.parse(localStorage.getItem('userDetails'));
    const [value, setValue] = useState({ 'profiles': [], 'path': '' });
    const [path, setpath] = useState('');
    const baseUrl = process.env.REACT_APP_BASE_URL;
    //console.log(path);
    const [display_list, setdisplay] = useState({
        about_me: "", age: "", country_name: "", gender: "",
        height: "",
        height_unit: "",
        images: [{ 'image': '' }],
        like_status: "",
        locked_status: "",
        mother_tongue: "",
        name: "",
        nationality_name: "",
        occupation_name: "",
        religion_name: "",
        report_user_list_status: "",
        request_list_id: "",
        request_status: "",
        selfie_status: "",
        shortlist_status: "",
        state_name: "",
        study_name: null,
        unique_id: "",
        user_id: "",
        weight: "",
        weight_unit: "",
        referral_link: ""
    })
    const location = useLocation();
    const [id, setid] = useState('');
    const latest_msg = JSON.parse(localStorage.getItem('msg_input'));
    const image_path = process.env.REACT_APP_IMAGE_PATH;
    const [mobileStatus, setMobileStatus] = useState(false);

    useEffect(() => {
        let query = ref(db, `MM4U/${local_storage.user_id}/conversations`);

        const unsubscribe = onValue(query, (snapshot) => {
            let data = snapshot.val();
        
            // Check if data is valid and contains at least one element
            if (data && Array.isArray(data) && data.length > 0) {
                setotheruid(data[0]?.other_user_id);
                setchatlist(data);
        
                // Set id based on window width
                if (window.innerWidth < 768) {
                    setid('');
                } else {
                    setid(data[0].id);
                }
        
                setUseritemid(data[0].other_user_id);
            } else {
                // Handle the case where data is null or empty
                setchatlist([]); // Set an empty chat list if no data
                setid(''); // Clear any previously set ID
                setUseritemid(''); // Clear any previously set UserItem ID
            }
        });
        

        const proFormData = new FormData();
        proFormData.append('user_id', local_storage.user_id);
        proFormData.append('profile_id', otheruid);
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/profile_view`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: proFormData
        }).then((res) => {
            setpath(res.data.path);
        }).catch((error) => {
            console.log(error);
        })

        return () => {
            off(query);
        };
    }, [otheruid]);

    var chat_list = chatlist.map((item) =>
        <button className="card-button" onClick={(e) => {
            window.innerWidth < 768 ? setMobileStatus(true) : setMobileStatus(false);
            setid(item.id, item.other_user_id);
            setUseritemid(item.other_user_id);
        }}>
            <Card className="chat_card mb-3"
                style={item.other_user_id == useritemid ? { backgroundColor: '#ffdddd' } : {}}>
                <CardHeader>
                    <div className="avatar_1">
                        {item.other_user_name.toUpperCase().substring(0, 1)}
                    </div>
                </CardHeader>
                <Card.Body className="p-1 d-flex align-items-center">
                    <Card.Text>
                        <span className="profile_name m-0 p-0"> {item.other_user_name} </span>
                        <br />
                        <span className="user_location m-0 p-0">
                            {item.Latest_message.msg}
                        </span>
                    </Card.Text>
                </Card.Body>
                <Card.Footer className="font-12">
                    {item.formattedDate} {item.formattedTime}
                </Card.Footer>
            </Card>
        </button>
    )
    //console.log(chat_list)
    setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, 1000);

    return (
        <div className="d-block d-lg-flex d-block">
            <div className="col-3 page_left" style={{ width: 'auto' }}>
                <Sidemenu />
            </div>
            <div className="col-9 d-flex flex-column chat-page page_right">
                <div className="d-flex flex-row bg-white top-bar shadow">

                    <p> <b>Chats</b> </p>
                </div>
                <div className="d-flex p-0 m-0">
                    <div className={mobileStatus ? "d-none" : "user_info col-12 col-sm-7 mt-4 mb-3 px-3 px-sm-5"}>
                        <p>{chat_list.length == '0' ?
                            <div className="d-flex justify-content-center align-items-center mt-5 w-100 h-100" >
                                <img src={NoData} />
                            </div>
                            : ''}
                        </p>
                        {chat_list}
                    </div>
                    <div className={mobileStatus ? "col-12 col-sm-7" : "col-0 col-sm-5"} id="right-card"
                        style={{ display: 'block' }}>
                        <div>
                            {Boolean(id) ?
                                <>
                                    <button style={{ backgroundColor: 'transparent', border: 'none', position: 'absolute', right: '5px', zIndex: '10' }}>
                                        <img src={deleteicon} className="close-button" alt=""
                                            style={{ width: '20px', height: '20px', borderRadius: '50%' }}
                                            onClick={(e) => { setid(''); setMobileStatus(false); }} />
                                    </button>
                                    <Newmsg convid={id} />
                                </>
                                :
                                ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chat;
