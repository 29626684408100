import React, { useState, useEffect } from "react";
import icon from "../../assets/images/lock_icon.png";
import { Button, Form, Modal } from 'react-bootstrap';
import OtpInput from "react-otp-input";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import LottieLoader from '../lottie/lottieloader';
import { set, useForm } from "react-hook-form";
import Select from 'react-select';

function Otp() {
  const [otherlogin, setotherlogin] = useState(false);
  const handleOtherClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [errormsg, setErrorMsg] = useState('')
  const { register: register3, formState: { errors: errors3 }, handleSubmit: handleSubmit3 } = useForm();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const flagUrl = process.env.REACT_APP_FLAG_URL;
  const [otp, setOtp] = useState(0);
  const [total, setTotal] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  //console.log(location);
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));
  const newnum = JSON.parse(localStorage.getItem('newnum'));
  const otpNum = [];
  const handleChange = (e) => {
    setOtp(e);
    otpNum.push(e)
    setTotal(otpNum)
    setDisabled(e.length !== 6);

  };

  const submitOtp = (e) => {
    setDisable(true)
    if (total === '') {
      alert('please enter otp');
      return false;
    }
    const regFormData = new FormData();
    let key;
    if (location.state !== null) {
      regFormData.append('user_id', location.state.user_id);
      key = location.state.api_key;
    } else {
      regFormData.append('user_id', local_storage.user_id);
      key = local_storage.api_key;
    }
    regFormData.append('otp', total);

    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/verify_otp`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key,
      },
      data: regFormData
    }).then((res) => {
      if (res.data.status === 1) {
        if (location.state == null) {
          navigate('/Login')
        } else {
          setTimeout(() => {
            navigate('/emailverify', { state: location.state })
          }, 2500);
        }
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        setDisable(false)
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e.response, 'e')
      if (e.message == 'Request failed with status code 401') {
        setotherlogin(true)
      }
      setDisable(false)
    })
  };

  const disAble = () => {
    document.getElementById('resend').disabled = true;
    setTimeout(function () {
      document.getElementById('resend').disabled = false;
    }, 30000)
  }
  const [otpData, setOtpData] = useState([]);
  const resendOtp = (e) => {
    e.preventDefault()
    setOtp(0)
    const resendFormData = new FormData();
    let key;
    if (location.state !== null) {
      resendFormData.append('user_id', location.state.user_id);
      key = location.state.api_key;
    } else {
      resendFormData.append('user_id', location.state.user_id)
      key = location.state.user_id.api_key;
    }
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/resend_otp`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key,
      },
      data: resendFormData
    }).then((res) => {
      Toastify({
        text: res.data.message,
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: "top", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: "linear-gradient(to right, #00b09b, #96c93d)",
        },
        onClick: function () { } // Callback after click
      }).showToast();
      disAble();
    }).catch((e) => {
      console.log(e.data.data, 'REEEE')
    })
  }

  const [editdisplay, setEditDisplay] = useState('true');
  const [selectedOption, setSelectedOption] = useState('');
  const Editfunction = () => {
    setEditDisplay('false');
  }
  const handleSelect = (e) => {
    setSelectedOption(e);
  };

  const [country, setcountry] = useState([]);

  let Flagurl = `${flagUrl}`;

  let newdata = country.map((item, i) => {
    return { value: item.country_name, text: `${item.country_name + ' + ' + item.code}`, code: item.code, icon: <img src={Flagurl + '/' + item.country_flag} width='18px' /> }
  })

  useEffect(() => {
    const regFormData = new FormData();
    let key;
    if (location.state !== null) {
      regFormData.append('user_id', location.state.user_id);
      key = location.state.api_key;
    } else {
      regFormData.append('user_id', local_storage.user_id)
      key = local_storage.api_key;
    }
    regFormData.append('type', 1);
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/security_questions`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key
      },
      data: regFormData
    }).then((res) => {
      //console.log(res.data.data[0].description);
    }).catch((e) => {
      if (e.message == 'Request failed with status code 401') {
        setotherlogin(true)
        console.log(e.message)
      }
    })

    axios({
      method: 'GET',
      url: `${baseUrl}/Registration/country_code`,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': Auth,
      }
    }).then((response) => {
      setcountry(response.data.data)
    }).catch((e) => {
      console.log(e, 'E')
    })
  }, [])

  let phonedata;
  let countrycodedata;

  if (location.state && location.state.mobile !== "") {
    phonedata = location.state.mobile;
    countrycodedata = location.state.country_code;
    if (countrycodedata === "91") {
        // setccdata("91");
        // alert();
    } else {
      // setccdata("44");
    }
  } else {
    phonedata = "";
  }

  const [ccdata, setccdata] = useState(countrycodedata);
  const [phone, setPhone] = useState(phonedata);
  
  const mobilechange = (e) => {
    setPhone(e.target.value);
  }

  const newNumber = () => {
    let code;
    let countrycodedata;

    if (location.state && location.state.country_code) {
      countrycodedata = location.state.country_code;
      if (countrycodedata === "91") {
        setccdata("91");
      } else {
        setccdata("44");
      }
    } else {
      setccdata("91");
      countrycodedata = "91";
    }

    if (selectedOption === "") {
      setccdata("91");
      code = "91";
    } else {
      code = selectedOption.code;
      setccdata(code);
    }

    let num = document.getElementById('newnum').value;
    setPhone(num);
    setEditDisplay('true');

    const regFormData = new FormData();
    let key;
    if (location.state !== null) {
      regFormData.append('user_id', location.state.user_id);
      key = location.state.api_key;
    } else {
      regFormData.append('user_id', local_storage.user_id)
      key = local_storage.api_key;
    }
    regFormData.append('country_code', code);
    regFormData.append('mobile', num);

    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/change_mobile`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key
      },
      data: regFormData
    }).then((res) => {
      if (res.data.status === 1) {
        localStorage.setItem("newnum", JSON.stringify(num));
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { }
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { }
        }).showToast();
      }
    }).catch((e) => {
      console.log(e, 'e')
    })
  }

  const handleEnterKeyPress = (event) => {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      if (otp.length === 6 && !disabled) {
        submitOtp();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEnterKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEnterKeyPress);
    };
  }, [otp, disabled]); // Adding otp and disabled to dependencies

  return (

    <div className="d-lg-flex d-block">
      <Modal show={otherlogin} size="lg" onHide={handleOtherClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => navigate('/Login')} className="up-btn">
            ok
          </Button>
        </Modal.Footer>
      </Modal>
      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}
      <div className="col-lg-6 col-12 otp_page"></div>
      <div className="col-lg-6 col-12 mt-5 content">

        <div className="text-center">
          <img src={icon}/>

          {editdisplay == "true" ?
            <div>
              <h5 className="mt-5"> My Code </h5>
              <p className="m-4">{location.state !== null ? <span> +{ccdata} {phone} </span> : newnum}
                <span style={{ color: "#E6368B", fontWeight: "500", fontSize: "11px", cursor: "pointer" }} onClick={Editfunction}> Edit  </span>
              </p>
              <div className="otp_input">
                <OtpInput 
                  inputStyle={{ width: '2rem', height: '2rem', margin: '10px 10px', fontSize: '1rem', border: 'none', borderBottom: '1px solid' }}
                  isInputNum='true'
                  maxLength='6'
                  numInputs={6}
                  value={otp}
                  onChange={handleChange}
                  separator={<span></span>}
                />
              </div>

              <button disabled={otp.length !== 6 || disabled} type="submit" onClick={submitOtp} className="btn btn-dark w-50 mt-4 btn_disabled">CONTINUE</button><br></br>
              <Button variant="light" onClick={(e) => resendOtp(e)} className="mt-4 resend_btn" id='resend'>RESEND CODE</Button>
            </div> :

            <div className="col-10 col-lg-6 content m-auto">
              <h5 className="mb-4"> Change Mobile Number </h5>
              <Form onSubmit={handleSubmit3(newNumber)}>
                <Form.Group className="mb-3" >
                  <div className="country_code">
                    <Select
                      // {...register("code", { required: true })}
                      className="select_country_code col-3"
                      components={{ IndicatorSeparator: () => null }}
                      placeholder={<p className="select_placeholder">
                        {selectedOption == "" && countrycodedata == "91" ? <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={`${flagUrl}/in.png`} width='18px' />
                          <span> +91 </span> </div>
                          :
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={`${flagUrl}/gb.png`} width='18px' />
                            <span> +44 </span>
                          </div>
                        }
                      </p>
                      }
                      value={selectedOption}
                      options={newdata}
                      onChange={handleSelect}
                      defaultCountryCode="+91"
                      id="newcode"
                      getOptionLabel={e => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            {e.icon}
                            <span style={{ marginLeft: 5 }}>+{e.code}</span>
                          </div>
                        </div>
                      )}
                    />

                    <Form.Control {...register3("mobile", { required: true, minLength: 10, maxLength: 15 })} type="number" placeholder="Enter your mobile number" className="col-9 number_field" id="newnum" value={phone} onChange={mobilechange} />

                  </div>
                  {errors3.mobile?.type === 'required' && <span className="error_msg_mob">Please enter Valid Mobile number</span>}
                  <span className='error_msg_mob'> {errors3.mobile?.type === 'maxLength' && "mobile number should be 10-15 numbers"}</span>
                  <span className='error_msg_mob'> {errors3.mobile?.type === 'minLength' && "mobile number should be 10-15 numbers"}</span>
                </Form.Group>
                <Button variant="primary" type="submit" className="reset_btn w-lg-50 w-auto mt-4">
                  CHANGE NUMBER
                </Button>
              </Form>
            </div>}
        </div>
      </div>
    </div>
  )
}

export default Otp;