import React, { useState, useEffect } from "react";
import plus from "../../assets/images/img_select1.svg";
import activePlus from '../../assets/images/img_sel.svg';
import { Form, Button, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import './addphotos.css'
import $ from 'jquery';
import LottieLoader from '../lottie/lottieloader';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


function AddPhotos() {

    const [otherlogin, setotherlogin] = useState(false);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const Auth = process.env.REACT_APP_USER_TOKEN;
    const navigate = useNavigate();
    const location = useLocation();
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
    let [images, setImages] = useState([])
    let [positions, setPositions] = useState(1)
    const [disable, setDisable] = useState(false);
    const [selectedImage, setSelectedImage] = useState('file-ip-1-preview')
    const [disable1, setDisable1] = useState({ 'display': '' });
    const [disable2, setDisable2] = useState({ 'display': '' });
    const [disable3, setDisable3] = useState({ 'display': '' });
    const [disable4, setDisable4] = useState({ 'display': '' });
    const [disable5, setDisable5] = useState({ 'display': '' });
    const regFormData = new FormData();
    const [setPhotos, getPhotos] = useState([]);
    const [deleted_image_ids, setDeleteImage] = useState('')
    const [photosData, setphotosData] = useState('')
    const local_storage = JSON.parse(localStorage.getItem('userDetails'));
    const [imageStatus, setimageStatus] = useState([])
    const [info, setinfo] = useState(false)
    const onPhotosSubmit = (data) => {
        setDisable(true)
        let key;
        if (location.state !== null) {
            regFormData.append('user_id', location.state.user_id);
            key = location.state.api_key;
        } else {
            regFormData.append('user_id', local_storage.user_id)
            key = local_storage.api_key;
        }
        // regFormData.append('userprofile[]', data.userprofile);
        for (let i = 0; i < images.length; i++) {
            regFormData.append(`userprofile[]`, images[i])
        }
        let yo = selectedImage.split("-")[2]
        regFormData.append('img_pos', parseInt(yo) - 1)
        regFormData.append('sel_img_id', '')
        regFormData.append('deleted_image_ids', deleted_image_ids)

        axios({
            method: 'POST',
            url: `${baseUrl}/Registration/add_photos`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': key,
            },
            data: regFormData

        }).then((res) => {
            //setDisable(false)

            if (res.data.status === 1) {
                if (location.state == null) {
                    navigate('/settings')
                } else {
                    setTimeout(() => {
                        navigate('/moreabout', { state: location.state })
                    }, 3000);
                }
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            } else {
                setDisable(false);
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            }
        }).catch((e) => {
            console.log(e.response, 'e')
            setDisable(false)
            if (e.message == 'Request failed with status code 401') {
                setotherlogin(true)
            }
        })
    }

    useEffect(() => {
        if (local_storage !== null) {
            const regFormData = new FormData();
            regFormData.append('user_id', local_storage.user_id);
            axios({
                method: 'POST',
                url: `${baseUrl}/Profiles/user_profile`,
                headers: {
                    "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                    'X-api-key': local_storage.api_key,
                },
                data: regFormData
            }).then((res) => {
                if (res.data.status === 1) {
                    if (res.data.data.selfie_status === '1') {
                        //console.log(res.data)
                        setValue({ 'name': res.data.data.display_name, 'image': `${res.data.path}/${res.data.data.selfie_pic}`, 'id': `#${res.data.data.unique_id}` })
                    } else {
                        setValue({ 'name': res.data.data.display_name, 'image': `${res.data.path}/${res.data.data.selfie_pic}`, 'id': `(#${res.data.data.unique_id})` })
                    }
                }
            }).catch((e) => {
                console.log(e)
            })
        }
    }, [])

    let count = 0;
    useEffect(() => {
        $(".progress-bar").animate({
            width: "70%"
        }, 2500);
        const getUserFormData = new FormData();
        let key;
        if (location.state !== null) {
            getUserFormData.append('user_id', location.state.user_id);
            key = location.state.api_key;
        } else {
            getUserFormData.append('user_id', local_storage.user_id)
            key = local_storage.api_key;
        }
        axios({
            method: 'POST',
            url: `${baseUrl}/Registration/get_photos`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': key,
            },
            data: getUserFormData
        }).then(async (res) => {
            for (let i = 0; i < res.data.data.images.length; i++) {
                const imgStatus = res.data.data.images[i].img_status;
                setimageStatus(prevStatuses => [...prevStatuses, imgStatus]);
            }
            const photoData = res.data.data.photo_status[0].photos_slider_status
            setphotosData(photoData)
            if (res.data.status === 1) {
                let fetchedImagesUrl = []
                count = res.data.data.images.length;
                getPhotos(res.data.data.images)
                setImages(res.data.data.images)
                for (let i = 0; i < res.data.data.images.length; i++) {
                    let preview = document.getElementById(`file-ip-${i + 1}-preview`);
                    preview.src = res.data.data.images[i].with_base;
                    preview.style.display = "block";
                    document.getElementById('dbutton').disabled = false;
                    document.getElementById(`file-ip-${i + 2}-preview`).src = activePlus;

                    if (res.data.data.images[i].display_pic == "1") {
                        setSelectedImage(`file-ip-${i + 1}-preview`)
                        setinfo(true)
                    }
                    document.getElementById(`file-ip-${i + 1}`).disabled = true;
                    removefun1()
                }
            }
            else {
                getPhotos([])
                setImages([])
            }
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    const showpre = () => {
        document.getElementById(`file-ip-1-preview`).src = plus;
        document.getElementById(`file-ip-2-preview`).src = plus;
        document.getElementById(`file-ip-3-preview`).src = plus;
        document.getElementById(`file-ip-4-preview`).src = plus;
        document.getElementById(`file-ip-5-preview`).src = plus;
        document.getElementById(`file-ip-6-preview`).src = plus;
        document.getElementById(`file-ip-${images.length + 1}-preview`).src = activePlus
        for (var i = 0; i < document.getElementsByClassName("imgRemove").length; i++) {
            document.getElementsByClassName("imgRemove")[i].style.display = "none"
        }
        document.getElementById("file-ip-1").disabled = false;
        document.getElementById("file-ip-2").disabled = false;
        document.getElementById("file-ip-3").disabled = false;
        document.getElementById("file-ip-4").disabled = false;
        document.getElementById("file-ip-5").disabled = false;
        document.getElementById("file-ip-6").disabled = false;
        if (images.length == 0) {
            document.getElementById("dbutton").disabled = true;
        }
        if (images.length == 1) {
            // document.getElementById(`file-ip-1-preview`).src=setPhotos[0].with_base;
            // document.getElementById(`removeButton1`).style.display="block";
            // document.getElementById(`removeButton1`).style.left="80%";
        }
        for (let i = 1; i <= images.length; i++) {
            let hasKey = typeof images[i - 1];
            let src = "";
            if (images[i - 1].hasOwnProperty('pic_id')) {
                src = images[i - 1].with_base;
            } else {
                src = URL.createObjectURL(images[i - 1]);
            }
            let preview = document.getElementById(`file-ip-${i}-preview`);
            setTimeout(() => {
                preview.src = src;
            }, 1000)
            document.getElementById('dbutton').disabled = false;
            document.getElementById(`removeButton${i}`).style.display = "block";
            document.getElementById(`removeButton${i}`).style.left = "80%";
            document.getElementById(`file-ip-${i}`).disabled = true;
            for (var t = 0; t < document.getElementsByTagName("img").length; t++) {
                document.getElementsByTagName("img")[t].classList.remove("border")
            }
            document.getElementById(`file-ip-${i}-preview`).classList.add("border")
        }
    }

    const showSinglePre = (id, file) => {
        let src = URL.createObjectURL(file);
        let preview = document.getElementById(`file-ip-${id}-preview`);

        if (preview) {
            setTimeout(() => {
                preview.src = src;
                preview.style.display = "block";
            }, 0);

            // Add border if id and selectedImage match
            if (selectedImage === `file-ip-1-preview` && id === 1) {
                preview.classList.add("border");
            }
        } else {
            console.warn(`Preview element with id file-ip-${id}-preview not found.`);
        }

        if (id <= 5) {
            const nextPreview = document.getElementById(`file-ip-${parseInt(id) + 1}-preview`);
            if (nextPreview) {
                nextPreview.src = activePlus;
            } else {
                console.warn(`Next preview element with id file-ip-${parseInt(id) + 1}-preview not found.`);
            }
        }

        const dbutton = document.getElementById('dbutton');
        if (dbutton) {
            dbutton.disabled = false;
        } else {
            console.warn(`Button with id dbutton not found.`);
        }

        const removeButton = document.getElementById(`removeButton${id}`);
        if (removeButton) {
            removeButton.style.display = "block";
            removeButton.style.left = "80%";
        } else {
            console.warn(`Remove button with id removeButton${id} not found.`);
        }
    };

    /*------------------------*/
    function showPreview(e) {
        const maxFileSize = 3 * 1024 * 1024; // 3 MB in bytes
        const file = e.target.files[0];

        document.getElementById(e.target.id).disabled = true; // Disable the file input

        // Check if a file is selected
        if (file) {
            // Check file size
            if (file.size > maxFileSize) {
                // Display error message using Toastify or any other method
                Toastify({
                    text: "The uploaded image is too large. Please try again with a file under 3MB.",
                    duration: 3000,
                    close: true,
                    gravity: "top",
                    position: "right",
                    backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)",
                }).showToast();
                e.target.value = ""; // Clear the input
                document.getElementById(e.target.id).disabled = false; // Re-enable the input
                return; // Exit the function if the file is too large
            }

            // If the file size is valid, proceed with setting images and showing preview
            setImages([...images, file]);
            getPhotos([...setPhotos, file]);
            showSinglePre(e.target.name, file);
        }
    }


    function removefun1() {
        if (count === 0) {
            document.getElementById('dbutton').disabled = true;
            setDisable1({ 'display': 'block' })
            setDisable2({ 'display': 'none' })
            setDisable3({ 'display': 'none' })
            setDisable4({ 'display': 'none' })
            setDisable5({ 'display': 'none' })
            document.getElementById(`removeButton1`).style.left = "80%";
        }
    }
    let previouslySelectedImageId = null; // Initialize with null

    function addColor(e) {
        const clickedImage = e.target;
        if (previouslySelectedImageId !== null) {
            // Remove the border from the previously selected image
            const previousImage = document.getElementById(previouslySelectedImageId);
            previousImage.classList.remove("border");
        }
        // Apply the border to the clicked image
        clickedImage.classList.add("border");
        setSelectedImage(clickedImage.id);
        setinfo(true)
        // Update the previously selected image ID
        previouslySelectedImageId = clickedImage.id;
    }
    function myImgRemoveFunction(index) {
        // Remove the image at the given index
        const updatedImages = images.filter((_, i) => i !== index);

        // Update the state with the new array
        setImages(updatedImages);
        getPhotos(updatedImages);

        // Reset the selected image if the removed one was selected
        if (selectedImage === `file-ip-${index + 1}-preview`) {
            setSelectedImage('');
        }

        // Handle updating the deleted images array if it has a pic_id
        if (setPhotos[index]?.pic_id) {
            setDeleteImage((prev) => [...prev, setPhotos[index].pic_id]);
        }

        // Refresh the previews to reflect the updated images
        refreshPreviews(updatedImages);

        // Re-enable the file input after deletion
        const inputElement = document.getElementById(`file-ip-${index + 1}`);
        if (inputElement) {
            inputElement.disabled = false; // Re-enable the file input
            inputElement.value = ""; // Clear the file input value
        }
    }


    function refreshPreviews(updatedImages) {
        // Loop through all 6 possible image slots
        for (let i = 0; i < 6; i++) {
            const previewElement = document.getElementById(`file-ip-${i + 1}-preview`);
            const inputElement = document.getElementById(`file-ip-${i + 1}`);

            if (previewElement && inputElement) { // Ensure elements exist
                if (updatedImages[i]) {
                    // If the slot has an image, display the image preview
                    previewElement.src = updatedImages[i].hasOwnProperty('with_base')
                        ? updatedImages[i].with_base
                        : URL.createObjectURL(updatedImages[i]);
                    previewElement.style.display = 'block'; // Ensure it's visible
                    inputElement.disabled = true; // Disable the input with an existing image
                } else {
                    // If no image is present, reset to the "plus" icon
                    previewElement.src = plus;
                    previewElement.style.display = 'block'; // Keep the slot visible
                    inputElement.disabled = false; // Enable empty slots
                }
            }
        }

        // Enable or disable the "Continue" button based on image count
        document.getElementById('dbutton').disabled = updatedImages.length === 0;
    }






    if (images.length > 0) {
        document.getElementById('dbutton').disabled = false;
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState({ source: '', status: '' });

    const viewImage = (imageSrc, status) => {
        //setModalImageSrc({ source: imageSrc, status: status });
        //setIsModalOpen(true);
        //console.log(imageSrc)
        let url
        if (imageSrc?.with_base) {
            setModalImageSrc({ source: imageSrc?.with_base, status: status });
            setIsModalOpen(true);
        }
        else if (imageSrc) {
            url = URL.createObjectURL(imageSrc)
            setModalImageSrc({ source: url, status: status });
            setIsModalOpen(true);
        }
        //co
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    return (
        <div className="d-lg-flex d-block">
            <Modal show={otherlogin} size="lg" animation={false} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
                <Modal.Footer>
                    <Button onClick={(e) => navigate('/Login')} className="up-btn">
                        ok
                    </Button>
                </Modal.Footer>
            </Modal>
            {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}

            <div className="col-6 photos_bg"></div>
            <div className="col-lg-6 col-12 mt-5 content_login">

                <div className="col-xs-12 col-sm-12 progress-container">
                    <div className="progress progress-striped active">
                        <div className="progress-bar progress-bar-success" ></div>
                    </div>
                    {location.state !== null ? <Button variant="danger" onClick={() => navigate("/about", { state: location.state })} className="back_button mt-3"><i className="fa fa-arrow-left">&nbsp; Back</i></Button> : ''}
                </div>
                <div className="text-center">
                    {/* {photosData == 0 && location.state == null ? <><p style={{ color: '#E6368B' }}><b>Note: Your photos under verification</b></p></> : ''}
                    {photosData == 2 ? <><p style={{ color: '#E6368B' }}><b>Note: Admin Rejected your photos, please upload again.</b></p></> : ''}<br></br>
                    <img src={icon} /> */}

                    <h4 className="mt-2">Add Photos</h4>
                    <span>Add at least 1 photo to continue</span>
                    <Form onSubmit={handleSubmit(onPhotosSubmit)}>

                        <div className="image-upload-container">
                            <div className="col-6 d-flex">
                                <div className="image-upload-one">
                                    <div className="center img-box">
                                        <div className="form-input">

                                            <label htmlFor="file-ip-1">
                                                {images[0] && (
                                                    <i className="fa fa-eye view" onClick={() => viewImage(setPhotos.length > 0 ? setPhotos[0] : activePlus, setPhotos[0]?.img_status)}></i>
                                                )}
                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <img
                                                        id="file-ip-1-preview"
                                                        src={setPhotos.length > 0 ? setPhotos[0].with_base : activePlus}
                                                        className={selectedImage == `file-ip-1-preview` ? `activePlus border` : `activePlus`}
                                                        onClick={(e) => addColor(e)}
                                                    />
                                                    {images[0] && imageStatus[0] === "2" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "84%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                background: "rgb(110 110 110 / 50%)",
                                                                color: "white",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            Rejected
                                                        </div>
                                                    )}
                                                    {images[0] && <button type="button" style={{ display: `${disable1.display}` }} id="removeButton1" className="imgRemove" name="1" onClick={(e) => myImgRemoveFunction(0)}></button>}
                                                    {selectedImage === "file-ip-1-preview" && images[0] && <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Hey! You've chosen this photo as your profile image, and you can change it anytime."}</Tooltip>}>
                                                        <span className="action-buttons">
                                                            <i class="fa fa-info info-pic"></i>
                                                        </span>

                                                    </OverlayTrigger>}
                                                </div>
                                            </label>
                                            {isModalOpen && (
                                                <div className="modal add_photos">
                                                    <div className="modal-content add_photos">
                                                        <span className="close" onClick={closeModal}>&times;</span>
                                                        <p>{modalImageSrc.status == '0' ? 'Pending' : modalImageSrc.status == '1' ? 'Accepted' : modalImageSrc.status == '2' ? 'Rejected' : ''}</p>
                                                        <img src={modalImageSrc.source} className="modal-image" />
                                                    </div>
                                                </div>
                                            )}
                                            <input {...register("userprofile")} type='file' name="1" id="file-ip-1" accept="image/*" onChange={(e) => showPreview(e)} />
                                        </div>

                                    </div>
                                </div>

                                <div className="image-upload-two">
                                    <div className="center img-box">
                                        <div className="form-input">

                                            <label htmlFor="file-ip-2">
                                                {images[1] && (
                                                    <i className="fa fa-eye view-1" onClick={() => viewImage(setPhotos.length > 0 ? setPhotos[1] : activePlus, setPhotos[1]?.img_status)}></i>
                                                )}
                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <img id="file-ip-2-preview" src={setPhotos.length > 1 ? setPhotos[1].with_base : plus} className={selectedImage == `file-ip-2-preview` ? `activePlus border` : `activePlus`} onClick={(e) => addColor(e)} />
                                                    {images[1] && imageStatus[1] === "2" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "84%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                background: "rgb(110 110 110 / 50%)",
                                                                color: "white",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            Rejected
                                                        </div>
                                                    )}
                                                    {images[1] && <button type="button" style={{ display: `${disable2.display}` }} id="removeButton2" className="imgRemove" name="2" onClick={(e) => myImgRemoveFunction(1)}></button>}
                                                    {selectedImage === "file-ip-2-preview" && images[1] && <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Hey! You've chosen this photo as your profile image, and you can change it anytime."}</Tooltip>}>
                                                        <span className="action-buttons" >
                                                            <i class="fa fa-info info-pic"></i>
                                                        </span>
                                                    </OverlayTrigger>}
                                                </div>
                                            </label>

                                            {images[0] && <input {...register("userprofile")} name="2" id="file-ip-2" type="file" accept="image/*" onChange={(e) => showPreview(e)} />}
                                        </div>

                                    </div>
                                </div>

                                <div className="image-upload-three">
                                    <div className="center img-box">
                                        <div className="form-input">
                                            <label htmlFor="file-ip-3">
                                                {images[2] && (
                                                    <i className="fa fa-eye view-2" onClick={() => viewImage(setPhotos.length > 0 ? setPhotos[2] : activePlus, setPhotos[2]?.img_status)}></i>
                                                )}
                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <img
                                                        id="file-ip-3-preview"
                                                        src={setPhotos.length > 2 ? setPhotos[2].with_base : plus}
                                                        className={selectedImage === "file-ip-3-preview" ? "activePlus border" : "activePlus"}
                                                        onClick={(e) => addColor(e)}
                                                    />
                                                    {images[2] && imageStatus[2] === "2" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "84%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                background: "rgb(110 110 110 / 50%)",
                                                                color: "white",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            Rejected
                                                        </div>
                                                    )}
                                                    {images[2] && <button style={{ display: `${disable3.display}` }} type="button" id="removeButton3" className="imgRemove" name="3" onClick={(e) => myImgRemoveFunction(2)}></button>}
                                                    {selectedImage === "file-ip-3-preview" && images[2] && <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Hey! You've chosen this photo as your profile image, and you can change it anytime."}</Tooltip>}>
                                                        <span className="action-buttons" >
                                                            <i class="fa fa-info info-pic"></i>
                                                        </span>
                                                    </OverlayTrigger>}
                                                </div>
                                            </label>

                                            {images[1] && <input {...register("userprofile")} type="file" name="3" id="file-ip-3" accept="image/*" onChange={(e) => showPreview(e)} />}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 d-flex">
                                <div className="image-upload-four">
                                    <div className="center img-box">
                                        <div className="form-input">
                                            <label htmlFor="file-ip-4">
                                                {images[3] && (
                                                    <i className="fa fa-eye view-3" onClick={() => viewImage(setPhotos.length > 0 ? setPhotos[3] : activePlus, setPhotos[3]?.img_status)}></i>
                                                )}

                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <img id="file-ip-4-preview" src={setPhotos.length > 3 ? setPhotos[3].with_base : plus} className={selectedImage == `file-ip-4-preview` ? `activePlus border` : `activePlus`} onClick={(e) => addColor(e)} />
                                                    {images[3] && imageStatus[3] === "2" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "84%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                background: "rgb(110 110 110 / 50%)",
                                                                color: "white",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            Rejected
                                                        </div>
                                                    )}
                                                    {images[3] && <button type="button" style={{ display: `${disable4.display}` }} id="removeButton4" className="imgRemove" name="4" onClick={(e) => myImgRemoveFunction(3)}></button>}
                                                    {selectedImage === "file-ip-4-preview" && images[3] && <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Hey! You've chosen this photo as your profile image, and you can change it anytime."}</Tooltip>}>
                                                        <span className="action-buttons" >
                                                            <i class="fa fa-info info-pic"></i>
                                                        </span>
                                                    </OverlayTrigger>}
                                                </div>
                                            </label>
                                            {images[2] && <input {...register("userprofile")} type="file" name="4" id="file-ip-4" accept="image/*" onChange={(e) => showPreview(e)} />}
                                        </div>
                                    </div>
                                </div>

                                <div className="image-upload-five">
                                    <div className="center img-box">
                                        <div className="form-input">
                                            <label htmlFor="file-ip-5">
                                                {images[4] && (
                                                    <i className="fa fa-eye view-4" onClick={() => viewImage(setPhotos.length > 0 ? setPhotos[4] : activePlus, setPhotos[4]?.img_status)}></i>
                                                )}

                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <img id="file-ip-5-preview" src={setPhotos.length > 4 ? setPhotos[4].with_base : plus} className={selectedImage == `file-ip-5-preview` ? `activePlus border` : `activePlus`} onClick={(e) => addColor(e)} />
                                                    {images[4] && imageStatus[4] === "2" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "84%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                background: "rgb(110 110 110 / 50%)",
                                                                color: "white",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            Rejected
                                                        </div>
                                                    )}
                                                    {images[4] && <button type="button" style={{ display: `${disable1.display}` }} id="removeButton5" className="imgRemove" name="5" onClick={(e) => myImgRemoveFunction(4)}></button>}

                                                    {selectedImage === "file-ip-5-preview" && images[4] && <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Hey! You've chosen this photo as your profile image, and you can change it anytime."}</Tooltip>}>
                                                        <span className="action-buttons" >
                                                            <i class="fa fa-info info-pic"></i>
                                                        </span>
                                                    </OverlayTrigger>}
                                                </div>
                                            </label>
                                            {images[3] && <input {...register("userprofile")} type="file" name="5" id="file-ip-5" accept="image/*" onChange={(e) => showPreview(e)} />}
                                        </div>
                                    </div>
                                </div>

                                <div className="image-upload-six">
                                    <div className="center img-box">
                                        <div className="form-input">
                                            <label htmlFor="file-ip-6">
                                                {images[5] && (
                                                    <i className="fa fa-eye view-5" onClick={() => viewImage(setPhotos.length > 0 ? setPhotos[5] : activePlus, setPhotos[5]?.img_status)}></i>
                                                )}
                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                    <img id="file-ip-6-preview" src={setPhotos.length > 5 ? setPhotos[5].with_base : plus} className={selectedImage == `file-ip-6-preview` ? `activePlus border` : `activePlus`} onClick={(e) => addColor(e)} />
                                                    {images[5] && imageStatus[5] === "2" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "84%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                background: "rgb(110 110 110 / 50%)",
                                                                color: "white",
                                                                fontSize: "16px",
                                                                fontWeight: "bold",
                                                                borderRadius: "8px"
                                                            }}
                                                        >
                                                            Rejected
                                                        </div>
                                                    )}
                                                    {images[5] && <button type="button" id="removeButton6" className="imgRemove" name="6" onClick={(e) => myImgRemoveFunction(5)}></button>}
                                                    {selectedImage === "file-ip-6-preview" && images[5] && <OverlayTrigger placement="bottom" overlay={<Tooltip placement="left">{"Hey! You've chosen this photo as your profile image, and you can change it anytime."}</Tooltip>}>
                                                        <span className="action-buttons" >
                                                            <i class="fa fa-info info-pic"></i>
                                                        </span>
                                                    </OverlayTrigger>}
                                                </div>
                                            </label>
                                            {images[4] && <input {...register("userprofile")} type="file" name="6" id="file-ip-6" accept="image/*" onChange={(e) => showPreview(e)} />}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <h6 className="mt-3">Note: Select a picture and set it as your display image with a single tap.<span> File size should not exceed 3MB</span>
                        </h6>
                        <Button type="submit" variant="primary" disabled={true} id='dbutton' className="agree_btn w-75 mt-3"  >
                            CONTINUE
                        </Button></Form>
                </div>

            </div >
        </div >
    )
}

export default AddPhotos;