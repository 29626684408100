import React, { useState } from "react";
import logo from "../../assets/images/logo.svg";
import "../../assets/css/header.css";
import { Navbar, Nav, Container } from 'react-bootstrap';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import LottieLoader from '../lottie/lottieloader';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";

function Header() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [gender, setGender] = useState('');
  const [value, setValue] = useState();
  const [looking, setLooking] = useState(0);
  const [disable, setDisable] = useState(false);
  const [lookingmen, setLookingmen] = useState(false)
  const [lookingwomen, setLookingwomen] = useState(false)

  const goTooLogin = () => {
    navigate('/LogIn')
  }

  const onGenderSubmit = (data, event) => {
    if (lookingmen || lookingwomen) {
      setDisable(true);
      let setGender = [];

      if (lookingmen) {
        setGender.push("1");
      }
      if (lookingwomen) {
        setGender.push("2");
      }

      const genderValue = parseInt(data.gender === "male" ? "1" : "2");

      navigate('/LogIn', { state: { gender: genderValue, looking: setGender.toString() } });
      //console.log(genderValue)
    } else {
      Toastify({
        text: "Please select looking for gender",
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
          background: "linear-gradient(to right, #00b09b, #96c93d)",
        },
        onClick: function () { },
      }).showToast();
    }
  };

  const onSelect = (e) => {
    if (e.target.value === 'male') {
      setLooking("1");
    } else {
      setLooking("2");
    }
  };

  const onSelectlookingm = (e) => {

    setLookingmen(!lookingmen)
  }
  const onSelectlookingf = (e) => {
    setLookingwomen(!lookingwomen)
  }

  return (
    <div className="main_bg">
      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}
      <div className="head">
        <Navbar fixed="top" expand="lg" className="black">
          <Container fluid>
            <Navbar.Brand href="/" className="col-6">
              <img
                src={logo}
                className="d-inline-block align-top site_logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-lg-0 menu col-6"
                style={{ maxHeight: '100px' }}
                navbarScroll
              >
                <Nav.Link onClick={() => navigate('/')} className="px-5 text-white active">Home</Nav.Link>
                <Nav.Link onClick={() => navigate('/aboutpage')} className="px-5 text-white">About</Nav.Link>
                <Nav.Link onClick={() => navigate('/membership')} href="#" className="px-5 text-white ">Membership</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div>
        <div className="banner_text">
          <h3>Join <span>Free</span> Today</h3>
          <form onSubmit={handleSubmit(onGenderSubmit)} className="m-0">
            <div className="text-white d-flex justify-content-around">
              <label className="gender_a" >I am:</label><span className="gender_1">
                <input for="check1" {...register("gender", { required: true })} onClick={(e) => { onSelect(e); setGender(e.target.value); }} type="radio" value="male"></input>
                <label id="check1">a man</label></span>

              <span className="gender_2">
                <input {...register("gender", { required: true })} onClick={(e) => { onSelect(e); setGender(e.target.value); }} type="radio" value="female" ></input><label>a woman</label>
              </span>
              <br></br>

            </div><hr class="hr-margin"></hr>

            <div className="text-white d-flex justify-content-around">
              <label className="gender_b">Looking for:</label><span className="gender_3">
                <input checked={lookingmen} onClick={(e) => onSelectlookingm(e)} type="radio" value="male"></input><label>a man </label></span><span className="gender_4">
                <input checked={lookingwomen} onClick={(e) => onSelectlookingf(e)} type="radio" value="female"></input><label>a woman</label></span><br></br>
            </div><hr class="hr-margin"></hr>
            <span className='error_msg_front'> {errors.gender?.type === 'required' && "*Please select your gender"}</span>
            <div className="text-center landing_btn">
              <button type="submit" className="btn btn-danger w-50 mb-1 reg_button">SIGN UP</button><br></br>
              <button onClick={(e) => goTooLogin(e)} type="button" className="btn btn-dark w-50 reg_button member_login">MEMBER LOGIN</button>
            </div>
          </form>
          <hr className="m-2"></hr>
           {/* <p className="text-white text-center store-btn"> Download the App Now</p>
            <div className="app_buttons d-flex justify-content-center store-btn">
                        <a href="https://play.google.com/store/" target="_blank"><button className="btn btn-outline-dark btn-icon-text bg-black text-white w-100"> <i className="fa fa-play btn-icon-prepend mdi-36px"></i> <span className="d-inline-block text-left"> <small className="font-weight-light d-block android">Get it on the</small> Google Play </span> </button></a>

                        <a href="https://www.apple.com/app-store/" target="_blank"><button className="btn btn-outline-dark btn-icon-text bg-black text-white ios_icon">
                            <i className="fab fa-apple btn-icon-prepend mdi-36px"></i> <span className="d-inline-block text-left"> <small className="font-weight-light d-block ios">Download on the</small> App Store </span> </button></a>
                    </div> */}
        </div>
      </div>
    </div>

  )
}

$(document).ready(function () {
  $(window).scroll(function () {
    let scroll = $(window).scrollTop();
    if (scroll > 120) {
      $(".black").css("background", "black");
      $(".site_logo").css("width", "30%");
    }

    else {
      $(".black").css("background", "transparent");
      $(".site_logo").css("width", "auto");
    }
  })
})

export default Header;
