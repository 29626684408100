import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import Routing from './Routing/Routing';
import React, { useEffect } from 'react';
//import runOneSignal from './PushNotification';

function App() {
  // useEffect(() => {
  //   runOneSignal();
  // })

  return (
    <div className="App">
      <Routing />
    </div>
  );
}

export default App;
