import React from "react";


const Videocall=()=> {
  return (
    <div> 

    </div>
  )
}

export default Videocall