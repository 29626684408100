import axios from "axios";
import icon from "../../assets/images/lock_icon.png";
import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import MultiRangeSlider from "multi-range-slider-react";
import "range-slider-element";
import $ from "jquery";
import LottieLoader from "../lottie/lottieloader";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import "./display.css";

function Looking() {
  const [otherlogin, setotherlogin] = useState(false);
  const [show, setShow] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [disable, setDisable] = useState(false);
  const [countries, setCountry] = useState([]);
  const [minValue, set_minValue] = useState(18);
  const [maxValue, set_maxValue] = useState(19);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const defaultAuth = process.env.REACT_APP_USER_TOKEN;
  const local_storage = JSON.parse(localStorage.getItem("userDetails"));
  const [questions, setQuestons] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const [getIntrests, setGetIntrests] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [motherTongue, setmotherTongue] = useState([]);
  const [mTongue, setmTongue] = useState({ list: [], selMT: [] });
  const [selectedmotherTongue, setSelectedmotherTongue] = useState("");
  const [getGender, setgetGender] = useState("");
  const [checkedMale, setCheckedMale] = useState("");
  const [checkedFemale, setCheckedFemale] = useState("");
  // const handleInput = (e) => {
  //   set_minValue(e.minValue);
  //   set_maxValue(e.maxValue);
  // };
  const handleInput = (e) => {
    //console.log("Event object:", e); // Check what properties e has
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
};


  const [selCoun, setSelcoun] = useState([]);
  const onMultipleSelectChange = (event) => {
    // alert()
    if (event.length > 5) {
      let eventt = event.pop();
      alert("You can select upto 5 countries only");
      return false;
    } else {
      let coun = [];
      event.map((e) => {
        coun.push(e);
      });
      setSelectedCountries(coun);
    }
  };
  const onMultipleSelectChangemt = (event) => {
    let key = "";
    for (let i = 0; i <= motherTongue?.length; i = i + 1) {
      if (motherTongue[i]?.label == "Dont mind") {
        key = motherTongue[i].key;
      }
    }
    let len = event.length;
    if (event.length > 1 && event[event.length - 1] == key) {
      for (let i = 0; i < len - 1; i++) {
        event.shift();
      }
    } else if (event.length > 1 && event[0] == key) {
      event.shift();
    }
    if (event.length > 5) {
      event.pop();
      alert("You can select upto 5 languages only");
      return false;
    }
    let coun = [];

    event.map((e) => {
      coun.push(e);
    });
    setSelectedmotherTongue(coun);
  };
  useEffect(() => {
    const getUserFormData = new FormData();
    let key;
    if (location.state !== null) {
      getUserFormData.append("user_id", location.state.user_id);
      key = location.state.api_key;
    } else {
      getUserFormData.append("user_id", local_storage.user_id);
      key = local_storage.api_key;
    }
    axios({
      method: "POST",
      url: `${baseUrl}/Registration/get_looking_for`,
      headers: {
        "Content-type":
          "multipart/form-data; charset=utf-8; boundary=" +
          Math.random().toString().substr(2),
        "X-api-key": defaultAuth,
      },
    })
      .then((res) => {
        let selmother;
        let selcountries;
        axios({
          method: "POST",
          url: `${baseUrl}/Registration/get_looking_for_details`,
          headers: {
            "Content-type":
              "multipart/form-data; charset=utf-8; boundary=" +
              Math.random().toString().substr(2),
            "X-api-key": key,
          },
          data: getUserFormData,
        })
          .then((response) => {
            if (response.data.status === 1) {
              const fetchData = async () => {
                const gender = response.data.data.prefer_gender;
                await setgetGender(gender);
                if (gender === "2") {
                  setCheckedMale(false);
                  setCheckedFemale(true);
                } else if (gender === "1") {
                  setCheckedMale(true);
                  setCheckedFemale(false);
                } else if (gender === "1,2") {
                  setCheckedMale(true);
                  setCheckedFemale(true);
                }
              };

              fetchData();
              setTimeout(async () => {
                //setValue('mother_tongue', res.data.data.prefer_mother_tongue)
                setValue("study", response.data.data.prefer_study);
                setValue("religion", response.data.data.prefer_religion);
                setValue("country", response.data.data.prefer_country);
              }, 100);

              response.data.data.prefer_min_age != "" &&
                set_minValue(response.data.data.prefer_min_age);
              response.data.data.prefer_max_age != "" &&
                set_maxValue(response.data.data.prefer_max_age);
              //c= res.data.data.prefer_country;

              if (response.data.data.prefer_country != "") {
                selcountries = response.data.data.prefer_country.split(",");
              }
              if (response.data.data.prefer_mother_tongue != "") {
                selmother = response.data.data.prefer_mother_tongue.split(",");
              }
              let motherTongueData = [];
              let newcoun = [];
              let datas1 = [];
              let newSelectedmt = [];
              let newSelectedCon = [];
              datas1.push(res.data.data);
              datas1.length > 0 &&
                datas1[0].mother_tongue.map((data, i) => {
                  let othercoun = {};
                  othercoun.key = data.mother_tongue_id;
                  othercoun.label = data.mother_tongue;
                  othercoun.disabled = true;
                  motherTongueData.push(othercoun);
                });

              datas1.length > 0 &&
                datas1[0].country.map((data, i) => {
                  let othercoun = {};
                  othercoun.key = data.country_id;
                  othercoun.label = data.country_name;
                  newcoun.push(othercoun);
                });
              setSelectedmotherTongue(selmother);
              setSelectedCountries(selcountries);
              setCountry(newcoun);
              setmotherTongue(motherTongueData);
              setGetIntrests(response.data.data.prefer_interests.split(","));
              setQuestons(datas1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((e) => {
        console.log(e);
      });

    $(".progress-bar").animate(
      {
        width: "42%",
      },
      2500
    );
  }, []);

  const onLookingSubmit = (data) => {
    setDisable(true);
    const genderValues = [];

    if (checkedMale) {
      genderValues.push("1");
    }

    if (checkedFemale) {
      genderValues.push("2");
    }
    data.gender = genderValues;
    var checkedValue = [];
    var inputElements = document.getElementsByClassName("getmore");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        checkedValue.push(inputElements[i].value);
        // break;
      }
    }
    const regFormData = new FormData();
    let key;
    if (location.state !== null) {
      regFormData.append("user_id", location.state.user_id);
      regFormData.append("gender", genderValues);
      //regFormData.append('prefer_gender', location.state.looking);
      key = location.state.api_key;
    } else {
      regFormData.append("user_id", local_storage.user_id);
      regFormData.append("gender", genderValues);
      key = local_storage.api_key;
    }
    regFormData.append("type", 1);
    regFormData.append(
      "mother_tongue",
      selectedmotherTongue ? selectedmotherTongue.toString() : ""
    );
    if (!selectedmotherTongue) {
      toastfunction("Please select Language");
      setDisable(false);
      return false;
    }
    regFormData.append("study", data.study);
    regFormData.append("nationality", data.nationality);
    regFormData.append("occupation", data.occupation);
    regFormData.append("ethnicity", data.ethnicity);
    regFormData.append("religion", data.religion);
    if (data.religion == "") {
      toastfunction("Please select Religion");
      setDisable(false);
      return false;
    }
    regFormData.append(
      "country",
      selectedCountries ? selectedCountries.toString() : ""
    );
    if (!selectedCountries) {
      toastfunction("Please select Country");
      setDisable(false);
      return false;
    }
    regFormData.append("state", data.state);
    regFormData.append("min_distance", data.min_distance);
    regFormData.append("max_distance", data.max_distance);
    regFormData.append("min_age", minValue);
    if (maxValue != "19") {
      regFormData.append("max_age", maxValue);
    }
    regFormData.append("interest", data.interest);
    if (data.interest == "") {
      toastfunction("Please select atleast one interest");
      setDisable(false);
      return false;
    }

    function toastfunction(data) {
      Toastify({
        text: data,
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
          background: "linear-gradient(to right, #00b09b, #96c93d)",
        },
      }).showToast();
    }

    axios({
      method: "POST",
      url: `${baseUrl}/Registration/set_looking_for`,
      headers: {
        "Content-type":
          "multipart/form-data; charset=utf-8; boundary=" +
          Math.random().toString().substr(2),
        "X-api-key": key,
      },
      data: regFormData,
    })
      .then((res) => {
        setDisable(false);

        if (res.data.status === 1) {
          if (location.state == null) {
            navigate("/matchedprofiles");
          } else {
            setTimeout(() => {
              navigate("/about", { state: location.state });
            }, 3000);
          }
          Toastify({
            text: res.data.message,
            duration: 2000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            },
            onClick: function () {}, // Callback after click
          }).showToast();
        } else {
          Toastify({
            text: res.data.message,
            duration: 2000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            },
            onClick: function () {}, // Callback after click
          }).showToast();
        }
      })
      .catch((e) => {
        console.log(e.response, "e");
        setDisable(false);
        if (e.message == "Request failed with status code 401") {
          setotherlogin(true);
        }
      });
  };

  let c;

  return (
    <div className="d-lg-flex d-block">
      <Modal show={otherlogin} size="lg" animation={false} centered aria-labelledby="contained-modal-title-vcenter">
        <Modal.Body className="text-center">
          Sorry! Someone has logged in with your account.Please check and come
          back
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => navigate("/Login")} className="up-btn">
            ok
          </Button>
        </Modal.Footer>
      </Modal>
      {disable ? (
        <div className="AddPetloadingImage">
          <LottieLoader />
        </div>
      ) : (
        ""
      )}

      <div className="col-6 looking_bg"></div>
      <div className="col-lg-6 col-12mt-5 content_login">
        <div className="col-xs-12 col-sm-12 progress-container">
          <div className="progress progress-striped active">
            <div className="progress-bar progress-bar-success"></div>
          </div>
          {location.state !== null ? (
            <Button variant="danger" onClick={() => navigate("/Displayname", { state: location.state })} className="back_button mt-3">
              <i className="fa fa-arrow-left">&nbsp; Back</i>
            </Button>
          ) : (
            ""
          )}
        </div>

        <div className="text-center">
          <img src={icon} />

          <Form className="mt-5 px-lg-5 px-3">
            <h4>Looking for</h4>

            <div class="mb-3 mt-3">
              <input type="checkbox" {...register("gender")}
                name="male"
                checked={checkedMale}
                onChange={() => setCheckedMale(!checkedMale)}
              />
              &nbsp; Male &nbsp;
              <input
                type="checkbox"
                {...register("gender")}
                name="female"
                checked={checkedFemale}
                onChange={() => setCheckedFemale(!checkedFemale)}
              />
              &nbsp; Female
            </div>

            {motherTongue && motherTongue.length > 0 ? (
              <DropdownMultiselect
                selected={selectedmotherTongue}
                handleOnChange={(e) => onMultipleSelectChangemt(e)}
                className="looking_mother_tongue mt-4"
                options={motherTongue}
                name="motherTongue"
                placeholder="Language"
              />
            ) : (
              ""
            )}
            <span className="error_msg">
              {" "}
              {errors.mother_tongue?.type === "required" &&
                "Please select Mother tongue"}
            </span>
            {/* <Form.Select
              {...register("study")}
              className="looking_dropdown optional mt-2"
              required
            >
              <option value="" disabled>
                Language
              </option>
              {motherTongue && motherTongue.length > 0
                ? motherTongue.map((data, i) => {
                    return (
                      <option key={i} value={data.mother_tongue_id}>
                        {data.mother_tongue}
                      </option>
                    );
                  })
                : null}
            </Form.Select> */}

            <Form.Select
              {...register("study")}
              className="looking_dropdown optional mt-2"
              required
            >
              {" "}
              <option value="" disabled>
                Studies (optional)
              </option>
              {questions && questions.length > 0
                ? questions[0].studies.map((data, i) => {
                    return (
                      <option key={i} value={data.study_id}>
                        {data.study_name}
                      </option>
                    );
                  })
                : ""}
            </Form.Select>

            <Form.Select
              {...register("religion")}
              className="looking_dropdown"
              required
            >
              <option value="" disabled>
                Religion
              </option>
              {questions && questions.length > 0
                ? questions[0].religion.map((data, i) => {
                    return (
                      <option key={i} value={data.religion_id}>
                        {data.religion_name}
                      </option>
                    );
                  })
                : ""}
            </Form.Select>


            {countries.length > 0 && (
              <DropdownMultiselect
                selected={selectedCountries}
                className="looking_countries"
                options={countries}
                name="countries"
                handleOnChange={(e) => onMultipleSelectChange(e)}
                placeholder="Country"
              />
            )}

            {show === 2 ? (
              <div>
                <h5 className="mt-3">Maximum Distance</h5>
                <Form.Range />
              </div>
            ) : (
              ""
            )}

            <h5 className="mt-3">Interests</h5>
            <div className="form-check px-0" id="activeweights">
              {questions && questions.length > 0
                ? questions[0].interest.map((data, i) => {
                    return (
                      <label key={i} className="custom-checkbox">
                        <input
                          {...register("interest")}
                          className="getmore custom-checkbox-input"
                          value={data.interest_id}
                          type="checkbox"
                          defaultChecked={
                            getIntrests &&
                            getIntrests.includes(data.interest_id)
                              ? true
                              : false
                          }
                        />
                        <span className="custom-checkbox-text">
                          {data.interest_name}
                        </span>
                      </label>
                    );
                  })
                : ""}
            </div>

            <h5 className="mt-4">Prefered age Ranges</h5>
            <MultiRangeSlider
    min={18}
    max={90}
    step={1}
    ruler={true}
    preventWheel={false}
    minValue={minValue}
    maxValue={maxValue}
    onChange={(e) => handleInput(e)}
/>

            <span className="error_msg">
              {" "}
              {errors.range?.type === "required" && "Please select range"}
            </span>
            <span className="error_msg">
              {" "}
              {minValue > maxValue || minValue < 18
                ? "Please select preferred age ranges"
                : ""}
            </span>
            <range-slider min="0" max="100" step="1"></range-slider>
            <Button
              variant="dark"
              type="submit"
              className="d-block m-auto mt-3 mb-3 w-100 btn_disabled"
              onClick={handleSubmit(onLookingSubmit)}
            >
              CONTINUE
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Looking;
