import React from "react";
import img_1 from "../assets/images/card_img1.svg";
import img_2 from "../assets/images/card_img2.svg";
import img_3 from "../assets/images/card_img3.svg";
import img_4 from "../assets/images/card_img4.svg";
import img_5 from "../assets/images/phones.png";
import img_6 from "../assets/images/phone2.png";
import img_7 from "../assets/images/phone3.png";
import Header from "./header/header";
import Footer from "./footer/footer";
import axios from "axios";

const landing = () => {
    return (
        <div>
            <Header />
            <div className="text-center mt-4">
                <p className="text mb-2">Online dating has become a fun and quick way to meet likeminded individuals. You could be one click away from finding your soulmate.</p><p> Let <span className="blink-soft" style={{ color: '#E6368B', fontWeight: '600' }}>Redated</span> make it happen!</p>
            </div>
            <div className="d-flex main_card mb-4">
                <div className="card card_body">
                    <img src={img_1} />
                    <div className="card-body text-center">
                        <h5 className="card-title">Simple to use </h5>
                        <p className="card-text">Easy steps to get connected</p>
                    </div>
                </div>

                <div className="card card_body">
                    <img src={img_2} />
                    <div className="card-body text-center">
                        <h5 className="card-title">Smart Matching</h5>
                        <p className="card-text"> Create connections with users that are like you.</p>
                    </div>
                </div>

                <div className="card card_body">
                    <img src={img_3} />
                    <div className="card-body text-center">
                        <h5 className="card-title">Filter & Refine</h5>
                        <p className="card-text">Refine your search and save browsing time</p>
                    </div>
                </div>

                <div className="card card_body">
                    <img src={img_4} />
                    <div className="card-body text-center">
                        <h5 className="card-title">Stay Connected</h5>
                        <p className="card-text">Be part of our community and make it happen</p>
                    </div>
                </div>
            </div>

            <Footer />
        </div>

    )
}

export default landing;