import { initializeApp } from "firebase/app";
import { getDatabase,ref,onValue } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyBhEalETYDe81U5PbPaBOQSeQgO0exImkY",
    authDomain: "mm4u-1649232215351.firebaseapp.com",
    databaseURL: "https://mm4u-1649232215351-default-rtdb.firebaseio.com",
    projectId: "mm4u-1649232215351",
    storageBucket: "mm4u-1649232215351.appspot.com",
    messagingSenderId: "770511414431",
    appId: "1:770511414431:web:737cfaaea80ec47f43eeaa",
    measurementId: "G-H2T8HCEJ7T"
  };

const firebase = initializeApp(firebaseConfig);
const db = getDatabase(firebase);

export {db};