import React, { useState, useEffect, useRef } from 'react'
import { getDatabase, ref, onValue, set, child, push, update, get } from "firebase/database";
import axios from "axios";
import { Input } from '@material-ui/core'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import send_btn from '../../assets/images/send_btn.svg';
import male1 from '../../assets/images/men_s.svg';
import female1 from '../../assets/images/women_s.svg';
import { db } from './firebase';
import SendMessage from './SendMessage';
import SignOut from './Signout';


function NewChat(props) {

    const conId = props.convid;
    //console.log("conId",conId);
    const ouid = props.convid;
    const local_storage = JSON.parse(localStorage.getItem('userDetails'));
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const scroll = useRef();
    const bottomRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const [img, setimg] = useState({ 'path': '', 'image': '' });
    const [name, setname] = useState();
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0'); // Ensure two digits for day
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Ensure two digits for month
    const year = currentDate.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    const time = new Date().toLocaleTimeString().replace(/\b(AM|PM)\b/g, '');
    const [userId, setuserId] = useState('');
    const [online, setonline] = useState(false);
    const [chatid, setchatid] = useState('');
    const [msg, setMsg] = useState('');
    const image_path = process.env.REACT_APP_IMAGE_PATH_CHAT;
    const [count, setCount] = React.useState(0);
    const [userpath, setuserpath] = useState('');
    const fcmToken = localStorage.getItem('fcmToken');
    //console.log("my fcm token is ", fcmToken);
    const [otherfcm_Token, setotherfcm_Token] = useState('');
    const [picturestatus, setpicturestatus] = useState('');
    const [selfie_pic_status, setselfie_pic_status] = useState('');
    const [gender, setGender] = useState('');
    const [BlockStatus, setBlockStatus] = useState('');

    const db = getDatabase();

    useEffect(() => {
        if (conId) {
            try {
                let query = ref(db, `MM4U/${conId}/Messages/`);
                onValue(query, (snapshot) => {
                    setMessages(snapshot.val());
                })
                //alert(conId);
                const starCountRef = ref(db, `MM4U/${conId}/Messages/`);
                onValue(starCountRef, (snapshot) => {
                    const data = snapshot.val();
                });
            }
            catch (e) {
                console.log(e)
            }
            
            let litem = conId.split('_');
            let uid = litem[1]
            let pid = litem[2]

            const proFormData = new FormData();
            proFormData.append('user_id', local_storage.user_id);
            //alert(uid+'uid');

            if (uid === local_storage.user_id) {
                proFormData.append('profile_id', pid)
            } else {
                proFormData.append('profile_id', uid)
            }
            axios({
                method: 'POST',
                url: `${baseUrl}/Profiles/profile_view`,
                headers: {
                    "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                    'X-api-key': local_storage.api_key,
                },
                data: proFormData

            }).then((res) => {
                console.log("The one and only main response is: [] ", res);
                //console.log("The one and only main response is: [] ", res);
                setimg((prevState) => ({
                    path: res.data.path,
                    image:
                         selfie_pic_status === '1' 
                            ? res.data.data.images[0].image
                            : gender === '1'
                                ? male1
                                : female1,
                }));
                setBlockStatus(res.data.data.blocked_by_others);
                //console.log(res.data.data.blocked_by_others);
                setname(res.data.data.name);
                setuserId(res.data.data.user_id);
                //console.log("The user id is: ", res.data.data.user_id);
                setchatid(res.data.data.chat_id);
                //code to be updated
                setpicturestatus(res.data.data.photos_slider_status);
                setselfie_pic_status(res.data.data.selfie_status);
                setGender(res.data.data.gender);
                //code to be updated
            }).catch((error) => {
                console.log(error);
            })
        }
    }, [conId, img])

    useEffect(() => {
        try {
            let query = ref(db, `MM4U/${userId}/`);
            onValue(query, (snapshot) => {
                //console.log("Other UserId is:", userId);
                //console.log("Other FCM TOken is: ", snapshot.val()[userId]);
                //console.log("Other FCM TOken is: ", snapshot.val().fcm_token);
                //console.log("Other Online Status: ", snapshot.val().onlineStatus);
                setotherfcm_Token(snapshot.val().fcm_token);
                setonline(snapshot.val().onlineStatus);
            })
        }
        catch (e) {
            console.log(e);
        }
    })

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        if (window.innerWidth > 768) { // Adjust the width as needed for your breakpoints
            bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id);
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/user_profile`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            //console.log(res);
            setuserpath(res.data.data.selfie_pic);
            // console.log(res.data.data.selfie_pic,'imageee');
        }).catch((e) => {
            console.log(e)
        })

    }, [messages]);


    function writeNewPost(e) {

        if (!messages) {
            set(ref(db, `MM4U/${conId}/Messages/`), {
            }).then((res) => {
                //console.log(res);
            })
                .catch((error) => {
                    console.log(error);
                });
        }
        let message = document.getElementById('msg_input').value;

        // A post entry.
        const postData = {
            "id": conId,
            "type": "text",
            "msg": message,
            "date": `${formattedDate} ${time}`,
            "sender_id": local_storage.user_id,
            "isRead": false,
            "isDelivered": false,
            "name": local_storage.name,
            "blocked_message": false
        };

        const latestMsg = {
            "Latest_message": {
                "date": `${formattedDate} ${time}`,
                "isDelivered": false,
                "isRead": false,
                "msg": message,
                "sender_id": local_storage.user_id
            },
            "blockedByOthers": false,
            "blockedByYouStatus": false,
            "id": chatid,
            "inChat": false,
            "other_user_id": local_storage.user_id,
            "other_user_name": local_storage.name,
            "other_user_pic": userpath,
            "yourTypingStatus": false,
        };

        //------------------ipdate latesh msg
        let query = ref(db, `MM4U/${local_storage.user_id}/conversations`);
        onValue(query, (snapshot) => {
            //console.log(snapshot.val(), 'SANP');
            let converstionsList = snapshot.val();
            var indexOfUserId = 0;
            localStorage.setItem("msg_input", JSON.stringify(message));
            const newPostKey = push(child(ref(db), 'posts')).key;
            const keyy = messages && messages.length > 0 ? messages.length : 0
            //Write the new post's data simultaneously in the posts list and the user's post list.
            const updates = {};
            updates[`MM4U/${conId}/Messages/` + keyy] = postData;

            const latestDataRef = ref(db, `MM4U/${local_storage.user_id}`);
            get(latestDataRef)
                .then((snapshot) => {
                    const existingLatestData = snapshot.val();
                     console.log("The existingLatestData is: ", existingLatestData);
                    // Update the fields you want to change in latestdata object
                    existingLatestData.delete_status = false;
                    existingLatestData.fcm_token = fcmToken;
                    existingLatestData.lastSeen = '';
                    existingLatestData.name = local_storage.name;
                    existingLatestData.notificationStatus = true;
                    existingLatestData.onlineStatus = true;
                    existingLatestData.pic = userpath;
                    existingLatestData.selfie_status = local_storage.selfie_status;
                    existingLatestData.gender = local_storage.gender;
                    existingLatestData.photos_slider_status = local_storage.photos_slider_status;

                    // Update latestMsg
                    updates['MM4U/' + userId + '/conversations/' + indexOfUserId + '/'] = latestMsg;

                    // Update latestdata in the database
                    updates[`MM4U/${local_storage.user_id}`] = existingLatestData;

                    return update(ref(db), updates);
                })
                .catch((error) => {
                    console.log(error);
                });

            return update(ref(db), updates);
        })

    }
    const makeinputEmpty = (e) => {
        setMsg(e.target.value);
        //pushNotificationAPI();
    }

    const makeinputEmpty1 = () => {
        setMsg('');
    }
    const dateArray = [];

    const pushNotificationAPI = () =>
    {
        axios({
            url: "https://fcm.googleapis.com/fcm/send",
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'key=AAAAs2YPJJ8:APA91bE8GCGEkirK59-pAelkQCkdS3fbyNCZl4BnXl5uSkwFj41IWCZSi48hW-fyQemIhJR2HfALz2gQqR03lZroQ8cM1L5kvtofCp14RtKm_UrGTAFf8Dqq0ws4xhNzC079gRBNvzju'
            },
            data: JSON.stringify({
                "to":otherfcm_Token,
                "notification":{
                    "body": msg,
                    "title":"New Message Received",
                    "subtitle":"subtitle"
                }
            })
        })
            .then(response => {
                //console.log('Notification sent successfully:', response.data);
            })
            .catch(error => {
                console.error('Error sending notification:', error);
            });
    }
    
    //pushNotificationAPI();

    return (
        <div>
            {/* <button onClick={pushNotificationAPI}>Push Notification</button> */}
            <Card>
                <div className='chat_head' >
                <img 
                    src={`${selfie_pic_status === '1' ? `${image_path}${img.image}` : gender === '1' ? male1 : female1}`}  
                    className="chat_pic" 
                    alt="Profile"
                />


                    <span className='chat_name'>
                        <b>{name}</b>
                    </span>
                    
                    {online == true ? 
                        <span className='online_status'>
                        <span class="green_dot"> 
                        </span>online</span> 
                        :
                        <span className='online_status'>
                        <span class="red_dot">
                        </span>offline</span>
                    }

                </div>
                <form>
                    <Card.Body style={{ overflowY: 'scroll', height: "85vh" }}>
                        <Card.Text>
                            <div className="msgs" >
                                {messages && messages.length > 0 && messages.map((item) => {
                                    if (dateArray.length == 0) {
                                        dateArray.push(`${item.date.substring(0, 10)}`);
                                    }
                                    else if (!dateArray.includes(`${item.date.substring(0, 10)}`)) {
                                        dateArray.push(item.date.substring(0, 10));
                                    }
                                })}
                                {messages && messages.length > 0 && messages.map((item) => (
                                    <div>
                                        {item.sender_id === local_storage.user_id ?
                                            (
                                                <div>
                                                    <div className='text-center' >
                                                        {dateArray.includes(`${item.date.substring(0, 10)}`) ?
                                                            (<div>
                                                                <span class="chat_scroll_data"> {item.date.substring(0, 10)} </span>
                                                                <span style={{ display: "none" }}> {dateArray.shift()} </span>
                                                            </div>) : ""}
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-end'>
                                                        <div className='msg sent'>
                                                            <p>{item.msg}
                                                                <span class="chat_time">
                                                                    {/* <sub> {item.date.substring(11, 16)} </sub> */}
                                                                </span> </p>
                                                        </div>
                                                        <div class="avatar"> {item.name.toUpperCase().substring(0, 1)} </div>
                                                    </div>
                                                </div>)
                                            :
                                            (<div className='d-flex align-items-center justify-content-start'>
                                                <div class="avatar"> {item.name.toUpperCase().substring(0, 1)} </div>
                                                <div className='msg received'>
                                                    <p>{item.msg} </p>
                                                </div>
                                            </div>)}
                                    </div>
                                ))}
                                <div ref={bottomRef} />
                            </div>
                        </Card.Text>
                        <div className="sendMsg">
                        {BlockStatus == '1' ? '': <Input value={msg} style={{ width: '78%', fontSize: '15px', fontWeight: '550', marginLeft: '5px', marginBottom: '-3px' }} placeholder='Type message...' type="text" id="msg_input" onChange={(e) => makeinputEmpty(e)} pattern="^[a-zA-Z][\sa-zA-Z]*" />}
                            {/* <Button  style={{ width: '18%', fontSize: '15px', fontWeight: '550', margin: '4px 5% -13px 5%', maxWidth: '200px'}} ></Button> */}
                            {BlockStatus == '1' ? <h6 style={{ color: '#E6368B' }}>Blocked</h6>: <Button type="submit" onClick={(e) => {
                                writeNewPost()
                                makeinputEmpty1()
                            }} className="chat_send" disabled={!msg}>
                                <img src={send_btn} />
                            </Button>}
                        </div>
                        <div ref={scroll}></div>
                    </Card.Body> </form>
            </Card>
            {/* <SendMessage scroll={scroll} /> */}
        </div>
    )
}

export default NewChat;