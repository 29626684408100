import React, { useEffect } from "react";
import icon from "../../assets/images/lock_icon.png";
import google from "../../assets/images/google_pass.png";
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import $ from 'jquery';
import { useState } from "react";
import Select from 'react-select';

function FacebookPass() {

  const [country, setcountry] = useState([]);
  const flagUrl = process.env.REACT_APP_FLAG_URL;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const navigate = useNavigate();
  const location = useLocation();
  const [passwordShown, setPasswordShown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const onFacebookPassword = (submitdata) => {
    const regFormData = new FormData();
    regFormData.append('user_id', location.state[0].user_id);
    regFormData.append('password', submitdata.password);
    regFormData.append('email', submitdata.email);
    regFormData.append('mobile', submitdata.mobile);
    regFormData.append('country', selectedOption.code);
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/password_set`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': location.state[0].api_key,
      },
      data: regFormData

    }).then((res) => {
      if (res.data.status === 1) {
        setTimeout(() => {
          navigate('/otp', { state: location.state })
        }, 2000);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e.response, 'e')
    })
  }

  const handleSelect = (e) => {
    setSelectedOption(e);
  };
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${baseUrl}/Registration/country_code`,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': Auth,
      }
    }).then((response) => {
      setcountry(response.data.data)
    }).catch((e) => {
      console.log(e, 'E')
    })
    $(document).ready(function () {

      $('#password').keyup(function () {
        var password = $('#password').val();
        if (checkStrength(password) == false) {
          $('#sign-up').attr('disabled', true);
        }
      });
      $('#confirm-password').blur(function () {
        if ($('#password').val() !== $('#confirm-password').val()) {
          $('#popover-cpassword').removeClass('hide');
          $('#sign-up').attr('disabled', true);
        } else {
          $('#popover-cpassword').addClass('hide');
        }
      });

      function checkStrength(password) {
        var strength = 0;
        if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
          strength += 1;
          $('.active_val').addClass('text-success pass_val');
          $('.low-upper-case i').removeClass('fa-file_text').addClass('');
          $('#popover-password-top').addClass('hide');
        } else {
          $('.active_val').removeClass('text-success pass_val');
          $('.low-upper-case i').addClass('fa-file_text').removeClass('');
          $('#popover-password-top').removeClass('hide');
        }
        if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
          strength += 1;
          $('.active_val1').addClass('text-success pass_val');
          $('.one-number i').removeClass('fa-file_text').addClass('k');
          $('#popover-password-top').addClass('hide');
        } else {
          $('.active_val1').removeClass('text-success pass_val');
          $('.one-number i').addClass('fa-file_text').removeClass('');
          $('#popover-password-top').removeClass('hide');
        }
        if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
          strength += 1;
          $('.active_val2').addClass('text-success pass_val');
          $('.one-special-char i').removeClass('fa-file_text').addClass('');
          $('#popover-password-top').addClass('hide');
        } else {
          $('.active_val2').removeClass('text-success pass_val');
          $('.one-special-char i').addClass('fa-file_text').removeClass('');
          $('#popover-password-top').removeClass('hide');
        }
        if (password.length > 7) {
          strength += 1;
          $('.active_val3').addClass('text-success pass_val');
          $('.eight-character i').removeClass('fa-file_text').addClass('');
          $('#popover-password-top').addClass('hide');
        } else {
          $('.active_val3').removeClass('text-success pass_val');
          $('.eight-character i').addClass('fa-file_text').removeClass('');
          $('#popover-password-top').removeClass('hide');
        }
        if (strength < 2) {
          $('#result').removeClass()
          $('#password-strength').addClass('progress-bar-danger');
          $('#result').addClass('text-danger').text('Very Week');
          $('#password-strength').css('width', '10%');
        } else if (strength == 2) {
          $('#result').addClass('good');
          $('#password-strength').removeClass('progress-bar-danger');
          $('#password-strength').addClass('progress-bar-warning');
          $('#result').addClass('text-warning').text('Week')
          $('#password-strength').css('width', '60%');
          return 'Week'
        } else if (strength == 4) {
          $('#result').removeClass()
          $('#result').addClass('strong');
          $('#password-strength').removeClass('progress-bar-warning');
          $('#password-strength').addClass('progress-bar-success');
          $('#result').addClass('text-success pass_val').text('Strength');
          $('#password-strength').css('width', '100%');
          return 'Strong'
        }
      }
    });
  }, []);
  let Flagurl = `${flagUrl}`
  let newdata = country.map((item, i) => {
    return { value: item.country_name, text: `${item.country_name + ' + ' + item.code}`, code: item.code, icon: <img src={Flagurl + item.country_flag} width='18px' /> }
  })

  return (
    <div className="d-flex">
      <div className="col-6 ">
        <img src={google} width="100%" />
      </div>
      <div className="col-6 mt-5 content">
        <div className="text-center">
          <img src={icon} />
        </div>
        <Form className="mt-5 px-5" onSubmit={handleSubmit(onFacebookPassword)}>
          <Form.Group >
            <Form.Label>Set new Password</Form.Label>
            <Form.Control {...register("password", { required: true, minLength: 8, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ })} type={passwordShown ? "text" : "password"} placeholder="Enter your Password" id="password" />
            <span className="pass_text1" onClick={togglePassword}>{passwordShown ? "hide" : "show"}</span>
            <span className='error_msg'> {errors.password?.type === 'required' && "Please enter password"}</span>
            <span className='error_msg'> {errors.password?.type === 'minLength' && "Password should be 8 characters with atleast 1 upper, 1 lower, 1 numeric and 1 special character"}</span>
            <span className='error_msg'> {errors.password?.type === 'pattern' && "Password should be 8 characters with atleast 1 upper, 1 lower, 1 numeric and 1 special character"}</span>

            <ul className="list-unstyled password_val">
              <li className="active_val3"><span className="eight-character"><i className="fa fa-file_text" aria-hidden="true"></i></span>&nbsp; 8+ Character</li>
              <li className="active_val2"><span className="one-special-char"><i className="fa fa-file_text" aria-hidden="true"></i></span> &nbsp;# Special </li>
              <li className="active_val"><span className="low-upper-case"><i className="fa fa-file_text" aria-hidden="true"></i></span>&nbsp; A Alphabet</li>
              <li className="active_val1"><span className="one-number"><i className="fa fa-file_text" aria-hidden="true"></i></span> &nbsp;1 number</li>
            </ul>
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Label>Email Address</Form.Label>
            <Form.Control {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} type="text" placeholder="Enter your email" />
            <span className='error_msg'> {errors.email?.type === 'required' && "Please enter email address"}</span>
            <span className='error_msg'>{errors.email?.type === 'pattern' && "Please enter valid E-mail address"}</span>
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Label>Add your mobile number</Form.Label>

            <div className="country_code">
              <Select
                className="select_country col-3"
                components={{ IndicatorSeparator: () => null }}
                placeholder={<p className="select_placeholder">+91</p>}
                value={selectedOption}
                options={newdata}
                onChange={handleSelect}
                defaultCountryCode="+91"
                getOptionLabel={e => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {e.icon}
                    <span style={{ marginLeft: 5 }}>{e.text}</span>
                  </div>
                )}
              />
              <Form.Control {...register("mobile", { required: true, minLength: 10, maxLength: 15 })} type="number" placeholder="Enter your mobile number" className="col-9 number_field" />
            </div>
            {errors.mobile?.type === 'required' && <span className="error_msg">Please enter Mobile number</span>}
            <span className='error_msg'> {errors.mobile?.type === 'maxLength' && "mobile number should be 10-15 numbers"}</span>
            <span className='error_msg'> {errors.mobile?.type === 'minLength' && "mobile number should be 10-15 numbers"}</span>
          </Form.Group>
          <Button variant="primary" type="submit" className="register_button w-100 mt-5 " >
            CONTINUE
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default FacebookPass;