import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Footer from "../footer/footer";
import { Navbar, Nav, Container, Button, Card, Form } from 'react-bootstrap';
import logo from "../../assets/images/logo.svg";
import { useNavigate } from 'react-router-dom';

function Membership() {
  
  const navigate = useNavigate();
  const [payment, setPayment] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const [currency, setCurrency] = useState([]);

  const [selectedCurrency, setSelectedCurrency] = useState({
    currency_code: "INR",
    currency_rate: 83.20
});

  useEffect(() => {
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/subscriptions`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': Auth,
      }
    }).then((res) => {
     // console.log(res.data.data)
      setPayment(res.data.data)
    }).catch((e) => {
      console.log(e)
    })

    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/currency_list`,
      headers: {
          "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
          'X-api-key': Auth,
      }
  }).then((res) => {
      setCurrency(res.data.data);
      const defaultCurrency = res.data.data.find(item => item.currency_code === "INR");
      if (defaultCurrency) {
          setSelectedCurrency({
              currency_code: defaultCurrency.currency_code,
              currency_rate: defaultCurrency.currency_rate
          });
      }
  }).catch((e) => {
      console.log(e)
  });

  }, [])

  const handleCurrencyChange = (event) => {
    const selectedCurrencyId = event.target.value;
    const selectedCurrencyData = currency.find(
        (item) => item.id === selectedCurrencyId
    );

    if (selectedCurrencyData) {
        setSelectedCurrency({
            currency_code: selectedCurrencyData.currency_code,
            currency_rate: selectedCurrencyData.currency_rate,
        });
    }
};

  const handleBuyNowClick = (item) => {
    navigate('/Login');
};

  return (
    <div>
      <Navbar expand="lg" className="home_data">
        <Container fluid>
          <Navbar.Brand href="/" className="col-6">
            <img
              src={logo} className='site_logo'
              style={{ display: 'block', margin: 'auto' }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-lg-0 menu col-6"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav.Link onClick={() => navigate('/')} className="px-5 text-white">Home</Nav.Link>
              <Nav.Link onClick={() => navigate('/aboutpage')} className="px-5 text-white">About</Nav.Link>
              <Nav.Link onClick={() => navigate('/membership')} href="#" className="px-5 text-white active">Membership</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className='p-lg-5 p-3'>
      <div className='text-center'>
      <h4>Unlock Premium Features!</h4>
<h4>Upgrade to Premium and enjoy exclusive benefits:</h4>
  <p><b>Send Profile Requests:</b> Connect with profiles that interest you.</p>
  <p><b>Shortlist Profiles:</b> Save your favorite profiles for easy access anytime.</p>
  <p><b>Unlimited Chat:</b> Start conversations with your matches instantly.</p>
<h6>Make your dating experience better—upgrade now and start connecting!</h6>
</div>
      <hr></hr>
      <h5 className='text-center mb-3'>Upgrade now to start building meaningful connections! Our Premium plans are based on the following timelines.</h5>
        <div className="d-lg-flex d-block justify-content-center align-items-center text-center">
        
                                <label>Choose Your Currency:</label>&nbsp;
                                <Form.Select
                                    aria-label="Currency selection"
                                    onChange={handleCurrencyChange}
                                    // value={selectedCurrency.currency_code}
                                    className="custom-currency-select"
                                >
                                    <option value="">Choose Currency</option>
                                    {currency.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.currency_code}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
     
      <div className="d-lg-flex d-block justify-content-center align-items-center">
                                {payment.map((item) => (
                                    <Card.Body key={item.plan_id} className='p-5'>
                                        <Button
                                            className="text-card shadow plan_btn p-0 col-12">
                                            <p className="up-btn text-white p-3" style={{ fontSize: '22px', fontWeight: '600' }}>{item.plan_name}</p>
                                            <p className="mt-5">{item.till_months}  Days</p>
                                            <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                                <span>{selectedCurrency.currency_code || "USD"}</span> {' '}
                                                {(
                                                    parseFloat(selectedCurrency.currency_rate || 1.0) *
                                                    parseFloat(item.plan_price)
                                                ).toFixed(2)}
                                            </p>
                                                <Button className="btn btn-danger up-btn mb-4 mt-4" onClick={() => handleBuyNowClick(item)}>
                                                    Upgrade
                                                </Button>
                                        </Button>
                                    </Card.Body>
                                ))}
                            </div>
        
        </div>
      <Footer />
    </div>

  )
}

export default Membership;