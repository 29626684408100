import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Sidemenu from "../sidebar/sidemenu";
import axios from 'axios'; // Add axios for HTTP requests
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import img from '../../assets/images/logo.svg'


function Checkout() {
  const { state } = useLocation();
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));
  const [loading, setLoading] = useState(false); // Loader state

  const [form, setForm] = useState({
    cardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cvc: '',
    firstName: local_storage.name,
    email: local_storage.email,
    phoneNumber: local_storage.mobile,
    address: '',
    city: '',
    zipCode: '',
    country: state?.currencyCode === 'INR' ? 'IN' :
    state?.currencyCode === 'USD' ? 'US' :
    state?.currencyCode === 'AED' ? 'AE' :
    state?.currencyCode === 'GBP' ? 'GB' :
    state?.currencyCode === 'PAK' ? 'PK' :
    state?.currencyCode === 'CAD' ? 'CA' :
              '',
    region: '',
    currency: state?.currencyCode || '',
    totalPrice: state?.planPrice || '',
    planName: state?.planName || '',
    planId: state?.planId || '',
    tillMonth: state?.tillMonth || '',
    userId: local_storage.user_id,
  });
//console.log(form,"form")

  const handleChange = (e) => {
    const { name, value } = e.target;

    let newValue = value;

    // Remove non-numeric characters and limit the length as needed
    const onlyNumbers = (val, limit) => val.replace(/\D/g, '').slice(0, limit);

    if (name === "cardNumber") {
      newValue = onlyNumbers(value, 16)  // Get only 16 digits
        .replace(/(\d{4})(?=\d)/g, '$1 '); // Add space every 4 digits
    }
    else if (name === "expiryMonth") {
      newValue = onlyNumbers(value, 2); // Allow only 2 digits for month
    }
    else if (name === "expiryYear") {
      newValue = onlyNumbers(value, 2); // Allow only 2 digits for year (e.g., '24')
    }
    else if (name === "cvc") {
      newValue = onlyNumbers(value, 3); // Allow only 3 digits for CVC
    }

    // Update the form state with the new value
    setForm((prevForm) => ({
      ...prevForm,
      [name]: newValue,
    }));
  };

  const showToast = (message) => {
    Toastify({
      text: message,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: "top", // Position the toast at the top
      position: "right", // Align to the right
      stopOnFocus: true, // Prevent dismissing on hover
      style: {
        background: "linear-gradient(to right, #00b09b, #96c93d)",
      },
    }).showToast();
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10,13}$/;
    const cardRegex = /^[0-9\s]{19}$/; // 16 digits + spaces
    const currentYear = new Date().getFullYear() % 100; // Last two digits of the current year
    const currentMonth = new Date().getMonth() + 1;

    if (!form.firstName) {
      showToast('First name is required.');
      return false;
    }
    if (!emailRegex.test(form.email)) {
      showToast('Invalid email format.');
      return false;
    }
    if (!phoneRegex.test(form.phoneNumber)) {
      showToast('Enter a valid phone number.');
      return false;
    }
    if (!form.address) {
      showToast('Enter your address.');
      return false;
    }
    if (!form.city) {
      showToast('Enter your city.');
      return false;
    }
    if (!form.zipCode) {
      showToast('Enter your zip code.');
      return false;
    }
    if (!form.country) {
      showToast('Enter your country.');
      return false;
    }
    if (!form.region) {
      showToast('Enter your region.');
      return false;
    }
    if (!cardRegex.test(form.cardNumber)) {
      showToast('Enter a valid card number.');
      return false;
    }
    if (!form.expiryMonth || form.expiryMonth < 1 || form.expiryMonth > 12) {
      showToast('Enter a valid month.');
      return false;
    }
    if (
      !form.expiryYear ||
      form.expiryYear.length !== 2 ||
      form.expiryYear < currentYear ||
      (form.expiryYear == currentYear && form.expiryMonth < currentMonth)
    ) {
      showToast('Enter a valid expiry year.');
      return false;
    }
    if (!form.cvc || form.cvc.length < 3 || form.cvc.length > 4) {
      showToast('Enter a valid CVC.');
      return false;
    }
    if (isNaN(form.totalPrice) || parseFloat(form.totalPrice) <= 0) {
      showToast('Invalid total price. Must be a positive number.');
      return false;
    }

    return true;
  };



  const handleSubmit = async (e) => {

    e.preventDefault();

    if (!validateForm()) return; // Stop if validation fails
    setLoading(true); // Show loader


    const paymentData = new FormData();
    paymentData.append('card_number', form.cardNumber);
    paymentData.append('expiry_month', form.expiryMonth);
    paymentData.append('expiry_year', form.expiryYear);
    paymentData.append('cvc', form.cvc);
    paymentData.append('first_name', form.firstName);
    paymentData.append('email', form.email);
    paymentData.append('phone_number', form.phoneNumber);
    paymentData.append('address', form.address);
    paymentData.append('city', form.city);
    paymentData.append('zip_code', form.zipCode);
    paymentData.append('country', form.country);
    paymentData.append('region', form.region);
    paymentData.append('currency', form.currency);
    paymentData.append('user_id', local_storage.user_id || '');
    const totalPrice = parseFloat(form.totalPrice) || 0.0;
    paymentData.append('total_price', totalPrice.toFixed(2));
    paymentData.append('plan_name', form.planName);
    paymentData.append('plan_id', form.planId);
    paymentData.append('validity_period', form.tillMonth);


    // Log FormData entries
    for (let [key, value] of paymentData.entries()) {
      //console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios({
        method: 'POST',
        url: `${baseUrl}/Payments/processOrder`,
        headers: {
          "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
          'X-api-key': local_storage.api_key,
        },
        data: paymentData,
      });

      //console.log(response.data, 'response')
      if (response.data.status === "success") {
        //console.log('Payment Successful:', response.data);
        // Toastify({
        //   text: 'Payment Successful!',
        //   duration: 3000,
        //   gravity: 'top',
        //   position: 'right',
        //   style: { background: 'linear-gradient(to right, #00b09b, #96c93d)' },
        // }).showToast();        // Redirect to the URL provided in the response
        const redirectUrl = response.data.redirect_url;
        //console.log('Redirecting to:', redirectUrl);
        window.location.href = redirectUrl;
      } else {
        console.error('Payment Failed:', response);
        Toastify({
          text: `Payment Failed: ${response.data.message}`,
          duration: 3000,
          gravity: 'top',
          position: 'right',
          style: { background: 'linear-gradient(to right, #ff5f6d, #ffc371)' },
        }).showToast();
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Payment Failed. Please try again.';
      console.error('Payment Error:', errorMessage);
      Toastify({
        text: errorMessage,
        duration: 3000,
        gravity: 'top',
        position: 'right',
        style: { background: 'linear-gradient(to right, #ff5f6d, #ffc371)' },
      }).showToast();
    }
    finally {
      setLoading(false); // Hide loader
    }
  };


  return (
    <div className="d-lg-flex d-block">
      <div className="col-3 page_left" style={{ width: 'auto' }}>
        <Sidemenu />
      </div>
      <div className="col-9 d-flex flex-column payment-page page_right">
        <div className="d-flex flex-row bg-white top-bar shadow">
        <img src={img} className="w-50 site_logo d-block m-auto inner-logo"/>
        </div>
        <div style={{ maxWidth: '600px', marginTop: '20px' }}>
          <Form onSubmit={handleSubmit} className="checkout-form">
            <div className="mb-4 p-3 head-div text-white text-center">
              <h2>Checkout</h2>
            </div>
            <div className="p-4">
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={form.firstName}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={form.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phoneNumber"
                      placeholder="+918520914522"
                      value={form.phoneNumber}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      placeholder="Address"
                      value={form.address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      placeholder="City"
                      value={form.city}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="zipCode"
                      placeholder="Zip Code"
                      value={form.zipCode}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      name="currency"
                      value={form.country}
                      readOnly
                    />

                    {/* <Form.Select
                      name="country"
                      value={form.country}
                      onChange={handleChange}
                    >
                      <option value="">Select a country</option>
                      <option value="IN">IN</option>
                    </Form.Select> */}
                  </Form.Group>

                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Region</Form.Label>
                    <Form.Control
                      type="text"
                      name="region"
                      placeholder="Region"
                      value={form.region}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3">
                <Form.Label>Currency</Form.Label>
                <Form.Control type="text" name="currency" value={form.currency} readOnly />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Total Price</Form.Label>
                <Form.Control type="text" name="totalPrice" value={form.totalPrice} readOnly />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Plan Name</Form.Label>
                <Form.Control type="text" name="planName" value={form.planName} readOnly />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Card Number</Form.Label>
                <Form.Control
                  type="text"
                  name="cardNumber"
                  placeholder="Card Number"
                  value={form.cardNumber}
                  onChange={handleChange}
                />
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Expiry Month</Form.Label>
                    <Form.Control
                      type="text"
                      name="expiryMonth"
                      placeholder="MM"
                      value={form.expiryMonth}
                      maxLength={2}  // Limit to 2 digits
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Expiry Year</Form.Label>
                    <Form.Control
                      type="text"
                      name="expiryYear"
                      placeholder="YY"
                      value={form.expiryYear}
                      maxLength={4}  // Limit to 4 digits
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3">
                <Form.Label>CVV</Form.Label>
                <Form.Control
                  type="text"
                  name="cvc"
                  placeholder="CVV"
                  value={form.cvc}
                  onChange={handleChange}
                />
              </Form.Group>

              <Button type="submit" variant="success" className="w-75 d-block m-auto" disabled={loading}>
                {loading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    Submitting...
                  </>
                ) : (
                  'Submit'
                )}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
