import React, { useState, useEffect } from "react";
import Sidemenu from "../sidebar/sidemenu";
import { useNavigate, useLocation } from 'react-router-dom';
import './payment.css';
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button, Card } from 'react-bootstrap';
import CardHeader from "react-bootstrap/esm/CardHeader";
import axios from "axios";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import img from '../../assets/images/logo.svg'


function Payment() {
    const [show, setShow] = useState(true);
    const location = useLocation();
    const [payment, setPayment] = useState([]);
    const [payStatus, setPayStatus] = useState([]);
    const [subscription, setSubscription] = useState([]);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const Auth = process.env.REACT_APP_USER_TOKEN;
    const [checkout, setCheckOut] = useState(false);
    const [currency, setCurrency] = useState([]);
    const local_storage = JSON.parse(localStorage.getItem('userDetails'));
    const navigate = useNavigate();

    const [selectedCurrency, setSelectedCurrency] = useState({
        currency_code: "INR",
        currency_rate: 83.20
    });

    useEffect(() => {
        const payData = new FormData();
        payData.append('user_id', local_storage.user_id);
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/subscriptions`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: payData
        }).then((res) => {
            setPayment(res.data.data)
            setPayStatus(res.data.data.pay_status)
        }).catch((e) => {
            console.log(e)
        });

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/currency_list`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': Auth,
            }
        }).then((res) => {
            setCurrency(res.data.data);
            const defaultCurrency = res.data.data.find(item => item.currency_code === "INR");
            if (defaultCurrency) {
                setSelectedCurrency({
                    currency_code: defaultCurrency.currency_code,
                    currency_rate: defaultCurrency.currency_rate
                });
            }
        }).catch((e) => {
            console.log(e)
        });

        payData.append('user_id', local_storage.user_id);
        axios({
            method: 'POST',
            url: `${baseUrl}/Payments/subscription_details`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            }, data: payData
        }).then((res) => {
            setSubscription(res.data.data);
        }).catch((e) => {
            console.log(e);
        });
    }, []);

    const handleCurrencyChange = (event) => {
        const selectedCurrencyId = event.target.value;
        const selectedCurrencyData = currency.find(
            (item) => item.id === selectedCurrencyId
        );

        if (selectedCurrencyData) {
            setSelectedCurrency({
                currency_code: selectedCurrencyData.currency_code,
                currency_rate: selectedCurrencyData.currency_rate,
            });
        }
    };

    const handleBuyNowClick = (item) => {
        if (!selectedCurrency && local_storage.subscription_status === '0') {
            Toastify({
                text: 'Please select a currency before proceeding.',
                duration: 3000,
                gravity: 'top',
                position: 'right',
                style: { background: 'linear-gradient(to right, #ff5f6d, #ffc371)' },
            }).showToast();
            return;
        }

        if (local_storage.subscription_status === '1') {
            Toastify({
                text: `You are already subscribed.`,
                duration: 3000,
                gravity: 'top',
                position: 'right',
                style: { background: 'linear-gradient(to right, #ff5f6d, #ffc371)' },
            }).showToast();
            return;
        }

        const checkoutData = {
            planId: item.plan_id,
            planName: item.plan_name,
            planPrice: (selectedCurrency.currency_rate * parseFloat(item.plan_price)).toFixed(2),
            currencyCode: selectedCurrency.currency_code,
            tillMonth: item.till_months
        };
        navigate('/checkout', { state: checkoutData });
    };

    return (
        <div className="d-block d-sm-flex">
            <div className="col-3 page_left" style={{ width: 'auto' }}>
                <Sidemenu />
            </div>
            <div className="col-9 d-flex flex-column payment-page page_right">
                <div className="d-flex flex-row bg-white top-bar shadow">
                <img src={img} className="w-50 site_logo d-block m-auto inner-logo"/>
                </div>
                <div className="d-flex p-0 m-0" style={{ width: '100%' }}>
                    <div className="user_info col-6 mt-4 mb-3 px-lg-5 px-2">
                        <Card className="payment-bottom-card mb-3">
                            <CardHeader className="justify-content-center">
                                <h5>Upgrade now to start building meaningful connections! Our Premium plans are based on the following timelines.</h5>
                            </CardHeader>
                            <hr></hr>
                            <div className="d-lg-flex d-block justify-content-center align-items-center">
                                <label>Choose Your Currency:</label>&nbsp;
                                <Form.Select
                                    aria-label="Currency selection"
                                    onChange={handleCurrencyChange}
                                    // value={selectedCurrency.currency_code}
                                    className="custom-currency-select"
                                >
                                    <option value="">Choose Currency</option>
                                    {currency.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.currency_code}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                            <div className="d-lg-flex d-block">
                                {payment.map((item) => (
                                    <Card.Body key={item.plan_id}>
                                        <Button
                                            className={`text-card shadow plan_btn p-0 col-12 ${item.pay_status === 'PAID' ? 'subscribed-plan' : ''}`}>
                                            <p className="up-btn text-white p-3" style={{ fontSize: '22px', fontWeight: '600' }}>{item.plan_name}</p>
                                            <p className="mt-5">{item.till_months} / Days</p>
                                            <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                                <span>{selectedCurrency.currency_code || "USD"}</span> {' '}
                                                {(
                                                    parseFloat(selectedCurrency.currency_rate || 1.0) *
                                                    parseFloat(item.plan_price)
                                                ).toFixed(2)}
                                            </p>

                                            {item.pay_status === 'PAID' ? (
                                                <>
                                                    <p className="mt-2 text-danger" style={{ fontSize: '16px', fontWeight: 'bold' }}>Plan Expires in {item.expire_days} Days</p>
                                                    <Button className="btn btn-secondary mb-2" onClick={() => handleBuyNowClick(item)}>
                                                        Subscribed
                                                    </Button>
                                                </>
                                            ) : (
                                                <Button className="btn btn-danger up-btn mb-4 mt-4" onClick={() => handleBuyNowClick(item)}>
                                                    Upgrade
                                                </Button>
                                            )}
                                        </Button>
                                    </Card.Body>
                                ))}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payment;
