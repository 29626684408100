import React from "react";
import { Navbar,Card,Row, Nav, Container, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import img from '../../assets/images/blog1.jpeg'
import './blog.css';


function blog() {
    return (
        <div>
            
          
          <div className="card natification">
          <div className="row">

              <div className="col-sm-1"><img className="card-img-left" src={img} width='18px' /> </div>
                <div className="col-sm-8"> <h5> <span>Aanya</span> liked your Profile</h5></div>
                <div className="col-sm-3"><p>30 Min Ago</p></div>
          </div>
          </div>
        </div>
    )
}
export default blog;