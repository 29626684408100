import React from 'react';
import animationData from '../../assets/Lottie/RejectCall.json';
import Lottie from 'react-lottie';
import '../lottie/lottie.css'

const LottieLoader = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <React.Fragment>
            <Lottie
                options={defaultOptions}
                height={80}
                width={150}
                isClickToPauseDisabled={true}
            />
        </React.Fragment>
    )
}
export default LottieLoader;