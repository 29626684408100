import React, { useState, useEffect } from "react";
import icon from "../../assets/images/lock_icon.png";
import './display.css';
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import $ from 'jquery';
import LottieLoader from '../lottie/lottieloader';
import moment from 'moment'

function Displayname() {
  var maxDate = new Date();
  var month = maxDate.getUTCMonth() + 1; //months from 1-12
  var day = maxDate.getUTCDate();
  var year = maxDate.getUTCFullYear();
  const [getDisplayName, setGetDisplayName] = useState("")
  const [name, setName] = useState('')
  const [dob, setDob] = useState('')
  const [otherlogin, setotherlogin] = useState(false);
  const handleOtherClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));
  const display_name = localStorage.getItem('name');

  const date = new Date(year, month, day)
  const result = date.toLocaleDateString("en-GB", { // you can use undefined as first argument
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
  useEffect(() => {
    if (local_storage) {
      setValue('display_name', local_storage.name)
    } else {
      setValue('display_name', display_name)
    }
  }, [])
  //console.log (setValue('dispaly_name'),'test')
  const onNameSubmit = (submitdata) => {
    setDisable(true)
    const formattedDate = moment(submitdata.dob).format('MM/DD/YYYY');
    const regFormData = new FormData();
    let key;
    if (location.state !== null) {
      regFormData.append('user_id', location.state.user_id);
      key = location.state.api_key;
    } else {
      regFormData.append('user_id', local_storage.user_id)
      key = local_storage.api_key;
    }
    regFormData.append('display_name', submitdata.display_name);
    regFormData.append('dob', formattedDate);
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/display_name`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key,
      },
      data: regFormData

    }).then((res) => {
      //  console.log(res, 'ressss')
      //setDisable(false)

      if (res.data.status === 1) {
        if (location.state == null) {
          navigate('/settings')
        } else {
          location.state.firstLooking = true;
          setTimeout(() => {
            navigate('/lookingfor', { state: location.state })
          }, 3000);

          const genderFormData = new FormData();
          genderFormData.append('user_id', location.state.user_id);
          genderFormData.append('gender', location.state.gender);
          axios({
            method: 'POST',
            url: `${baseUrl}/Registration/gender`,
            headers: {
              "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
              'X-api-key': key,
            },
            data: genderFormData

          }).then((res) => {
            //  console.log(res);
          }).catch((e) => {
            console.log(e);

          })

        }
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        setDisable(false);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }
    }).catch((e) => {
      console.log(e.response, 'e')
      setDisable(false)
      if (e.message == 'Request failed with status code 401') {
        setotherlogin(true)
      }
    })
  }

  const validateDate = (value) => {
    const selected = new Date(value).getFullYear();
    const now = new Date().getFullYear();
    return now - selected >= 18;
  };
  useEffect(() => {
    $(".progress-bar").animate({
      width: "28%"
    }, 2500);
    const getUserFormData = new FormData();
    let key;
    if (location.state !== null) {
      getUserFormData.append('user_id', location.state.user_id);
      key = location.state.api_key;
    } else {
      getUserFormData.append('user_id', local_storage.user_id)
      key = local_storage.api_key;
    }
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/get_display_name`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key,
      },
      data: getUserFormData
    }).then((res) => {
      if (res.data.status === 1) {
        // setGetDisplayName(res.data.data)
        if (location.state !== null) {
          let d = res.data.data.dob.split('/')
          let n = d[2] + '-' + d[0] + '-' + d[1]
          // console.log(n)
          setValue('dob', n)
        } else {
          let d = res.data.data.dob.split('/')
          let n = d[2] + '-' + d[0] + '-' + d[1]
          // console.log(n)
          setValue('dob', n)
          //setValue('dob', res.data.data.dob)
        }
        //setValue('display_name', res.data.data.display_name)

        if (res.data.data.display_name === "") {

          document.getElementById('dbutton').disabled = true;
        } else {
          document.getElementById('dbutton').disabled = false;
        }
      }
    }).catch((error) => {
      console.log(error)
    })
  }, [])

  const displayName = (e) => {
    setName(e.target.value);
    if (dob !== '') {
      document.getElementById('dbutton').disabled = false;
    }
    //  console.log(name)
    if (dob !== '') {
      document.getElementById('dbutton').disabled = false;
    }
  }

  const displayDob = (e) => {
    setValue('dob', e.target.value)
    setDob(e.target.value);
    // console.log(dob)
    document.getElementById('dbutton').disabled = false;
  }

  return (
    <div className="d-lg-flex d-block">
      <Modal show={otherlogin} size="lg" onHide={handleOtherClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => navigate('/Login')} className="up-btn">
            ok
          </Button>
        </Modal.Footer>
      </Modal>
      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}

      <div className="col-6 display_bg"></div>
      <div className="col-lg-6 col-12 mt-5 content_login">
        <div className="col-xs-12 col-sm-12 progress-container">
          <div className="progress progress-striped active">
            <div className="progress-bar progress-bar-success" ></div>
          </div>
        </div>
        <div className="text-center">
          <img src={icon} />
          <h4 className="mt-5">Display name</h4>
        </div>
        {/* defaultValue={getDisplayName && getDisplayName.display_name}
        defaultValue={getDisplayName && getDisplayName.dob}
        */}
        <Form className="mt-3 px-5" onSubmit={handleSubmit(onNameSubmit)}>
          <Form.Group className="mb-3" controlId="formBasicUser">
            <Form.Control  {...register("display_name", { required: true })} type="text" className="display_input" />
          </Form.Group>
          <span className="note_p">This is how it will appear in Redated.</span>
          <h4 className="mt-5 text-center">Date of birth</h4>
          <Form.Group className="mb-3" controlId="formBasicUser">
            <Form.Control
              {...register("dob", { required: true, validate: validateDate })}
              type="date"
              onChange={displayDob}
              placeholder="Date of Birth"
              max={moment().subtract(18, "years").format('YYYY-MM-DD')}
              min={moment().subtract(90, "years").format('YYYY-MM-DD')}
            />
            <span className='error_msg'> {errors.dob?.type === 'required' && "Please enter date "}</span>
            <span className='error_msg'>{errors?.dob?.type === "validate" && <p>Please select age range in between 18-90 years</p>} </span>
          </Form.Group>
          <span className="note_p">Your age will be public but not your date of birth</span>
          <Button variant="secondary" type="submit" id='dbutton' disabled={true} className="register_button w-100 mt-5 " >
            CONTINUE
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default Displayname;