import React, { useState, useEffect } from "react";
import Sidemenu from "../sidebar/sidemenu";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';
import './matchedprofiles.css';
import { Button, Card, Carousel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Toastify from 'toastify-js';
import like from '../../assets/images/outlike.png';
import info from '../../assets/images/info.png';
import liked from '../../assets/images/outliked.png';
import shortlist from '../../assets/images/outshortlist.png';
import shortlist1 from '../../assets/images/outshortlist1.png';
import shortlisted from '../../assets/images/shortlisted.svg';
import interest from '../../assets/images/outinterest.png';
import interested from '../../assets/images/interested.png';
import skip from '../../assets/images/outskip.png';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import male from '../../assets/images/male.png';
import female from '../../assets/images/female.png';
import male1 from '../../assets/images/men_s.svg';
import female1 from '../../assets/images/women_s.svg';
import map from '../../assets/images/location.png';
import LottieLoader1 from '../lottie/findprofiles';
import LottieLoader3 from '../lottie/lottieloader3';
import deleteicon from '../../assets/images/delete-icon.jpg';
import { getDatabase, ref, update } from "firebase/database";
import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
// import { Link } from "react-router-dom";
import OneSignal from 'react-onesignal';
import clipboardCopy from 'clipboard-copy';
import addNotification from 'react-push-notification';
import { db } from './firebase';
import { onValue } from "firebase/database";
import PaymentModel from "../Model/paymentModel";
import img from '../../assets/images/logo.svg'


function Matchedprofiles() {
    OneSignal.Notifications.addEventListener('notificationDisplay', function (event) {
        console.log('OneSignal notification displayed:', event);
    });

    const [closeaction, setcloseaction] = useState('true');
    const [shareprofile, setshare] = useState(false)
    const close = () => {
        setcloseaction('false');
    }
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const Auth = process.env.REACT_APP_USER_TOKEN;
    const proUrl = process.env.REACT_APP_PROFILE_LINK;
    const [loc, setLocation] = useState({ 'lat': '', 'lng': '' });
    const [show, setShow] = useState(true);
    const [right, setright] = useState(0)
    const location = useLocation();
    const [disable, setDisable] = useState(false);
    const navigate = useNavigate();
    const [lottie, setlottie] = useState(false)
    let [count, setCount] = useState(0)
    let [type, setType] = useState(3)
    const [photoStatus, setphotoStatus] = useState([])
    const [genderStatus, setgenderStatus] = useState([])
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [value, setValue] = useState({ 'profiles': [], 'path': '' })
    const [display_list, setdisplay] = useState({
        about_me: "", age: "", country_name: "", gender: "",
        height: "",
        height_unit: "",
        images: [{ 'image': '' }],
        like_status: "",
        locked_status: "",
        mother_tongue: "",
        name: "",
        nationality_name: "",
        occupation_name: "",
        religion_name: "",
        report_user_list_status: "",
        request_list_id: "",
        request_status: "",
        selfie_status: "",
        shortlist_status: "",
        state_name: "",
        study_name: null,
        unique_id: "",
        user_id: "",
        weight: "",
        weight_unit: "",
        photos_slider_status: "",
        display_name: ""
    })
    const screenWidth = window.innerWidth; // Get the current screen width

    // Calculate perView based on screen width
    let perView = 2.5; // Default perView value
    if (screenWidth >= 1920) {
        perView = 3.8; // Adjust perView for larger screens
    } else if (screenWidth < 1366) {
        perView = 2; // Adjust perView for smaller screens
    }

    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);

    const [sliderRef, instanceRef] = useKeenSlider({
        loop: false,
        drag: false,
        slides: {
            perView: 2, // Default for smaller screens
            spacing: 0,
        },
        breakpoints: {
            "(min-width: 1920px)": {
                slides: {
                    perView: 4, // Display 3 slides at 1920px or wider
                    spacing: 0,
                },
            },
        },
        breakpoints: {
            "(max-width: 767px)": {
                slides: {
                    perView: 1, // Display 3 slides at 1920px or wider
                    spacing: 0,
                },
            },
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setLoaded(true);
            if (instanceRef.current) {
                instanceRef.current.update(); // Ensure slider updates after creation
            }
        },
    });

    // const [sliderRef] = useKeenSlider({
    //     slides: {
    //         perView: perView,
    //         spacing: 0,
    //     },
    // });

    let key;
    if (location.state !== null) {
        localStorage.setItem("userDetails", JSON.stringify(location.state))
    }
    const local_storage = JSON.parse(localStorage.getItem('userDetails'));

    function makePageTransparent() {
        var overlay = document.getElementById('overlay');
        overlay.style.display = 'block';
    }

    function makePagenotTransparent() {
        var overlay = document.getElementById('overlay');
        overlay.style.display = 'none';
    }

    function showPosition(position) {
        let loc = `Latitude:${position.coords.latitude}  logitude:
       ${position.coords.longitude}`

        setLocation({ 'lat': `${position.coords.latitude}`, 'lng': `${position.coords.longitude}` })
    }

    function getLocation() {
        if (navigator.geolocation) {
            return navigator.geolocation.getCurrentPosition(showPosition);
        }
        return false;
    }
    let path = 0;
    const handleClose = () => {
        //     if (navigator.geolocation) {
        //         navigator.geolocation.watchPosition(showPosition);
        //      }
        //      console.log(navigator.geolocation)
        //    // }

        //    function showPosition(position) {
        //    setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
        //  console.log(position.coords.latitude,position.coords.longitude)
        //    }
        setShow(false)
    };


    useEffect(() => {
        const profileview = localStorage.getItem('profileview');
        if (profileview != null) {
            window.location.href = profileview;
            localStorage.removeItem('profileview');
        }
        //function to change the Online Status Starts Here
        const db = getDatabase();
        const userRef = ref(db, 'MM4U/' + local_storage.user_id);
        const updates = {
            onlineStatus: "true"
        };
        update(userRef, updates)
            .then(() => {
                //console.log("Online status updated successfully");
            })
            .catch((error) => {
                console.error("Error updating online status: ", error);
            });
        //function to change the Online Status Ends Here
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                },
                (error) => {
                    console.error('Error getting locaation:', error.message);
                }
            )
        } else {
            console.error('Geolocation is not supported by this browser');
        }

        makePageTransparent();
        setDisable(true)
        getLocation()
        get_matched_profiles()
    }, [latitude, longitude]);

    const get_matched_profiles = () => {
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id);
        regFormData.append('latitude', latitude);
        regFormData.append('longitude', longitude);
        regFormData.append('type', 1);
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/matched_profiles`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData

        }).then((res) => {
            setDisable(false)
            if (res.data.status === 1) {
                for (let i = 0; i <= res.data.data.length; i++) {
                    const photoData = res.data.data[i].photos_slider_status
                    const genderData = res.data.data[i].gender
                    setphotoStatus(photoData);
                    setgenderStatus(genderData);
                    const proFormData = new FormData();
                    proFormData.append('user_id', local_storage.user_id);
                    let profile_list = []
                    if (res.data.data.length >= 1) {
                        if (res.data.data.length >= 1) {
                            for (let d in res.data.data) {
                                profile_list.push(res.data.data[d])
                            }
                        }
                        setTimeout(() => {
                            setValue({ 'profiles': profile_list, 'path': res.data.path });
                        }, 2000);
                    }
                }
            }
            else {
                setlottie(true);
                makePagenotTransparent();
            }
        }).catch((error) => {
            console.log(error);
            setTimeout(() => {
                makePagenotTransparent();
            }, 3000);
        })

    }

    const handleskip = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/skip`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                let l_items = value.profiles.filter((item) => item.user_id !== new_id)
                setValue({ 'profiles': l_items, 'path': value.path })
                setdisplay({ user_id: '' })
            }
            Toastify({
                text: res.data.message,
                duration: 2000,
                newWindow: true,
                close: true,
                gravity: "top", // `top` or `bottom`
                position: "right", // `left`, `center` or `right`
                stopOnFocus: true, // Prevents dismissing of toast on hover
                style: {
                    background: "linear-gradient(to right, #00b09b, #96c93d)",
                },
                onClick: function () { } // Callback after click
            }).showToast();

        }).catch((error) => {
            console.log(error)
        })
    }
    
    const profileview = JSON.parse(localStorage.getItem('userDetails'));
    const handleinterest = event => {
        
        //console.log(profileview.subscription_status, 'profileview');
        if (profileview.subscription_status == 0) {
            setModalShow(true);
            return false;
        }
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/send_request`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                let l_items = value.profiles
                setValue({ 'profiles': l_items, 'path': value.path })
                setdisplay({ user_id: '' })
                let Imgsrc = document.getElementById(`interestImg-${new_id}`);
                if (Imgsrc) {
                    Imgsrc.src = res.data.message === 'Request sent successfully' ? `${interested}` : `${interest}`;
                }
            }
            Toastify({
                text: res.data.message,
                duration: 2000,
                newWindow: true,
                close: true,
                gravity: "top", // `top` or `bottom`
                position: "right", // `left`, `center` or `right`
                stopOnFocus: true, // Prevents dismissing of toast on hover
                style: {
                    background: "linear-gradient(to right, #00b09b, #96c93d)",
                },
                onClick: function () { } // Callback after click
            }).showToast();

        }).catch((error) => {
            console.log(error)
        })

    }
    const handlelike = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/user_likes`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                if (res.data.message === "Profile Liked") {
                    document.getElementById(`likes-${new_id}`).src = `${liked}`
                    setdisplay({
                        about_me: display_list.about_me,
                        age: display_list.age,
                        country_name: display_list.country_name,
                        gender: display_list.gender,
                        height: display_list.height,
                        height_unit: display_list.height_unit,
                        images: display_list.images,
                        like_status: '1',
                        locked_status: display_list.locked_status,
                        mother_tongue: display_list.mother_tongue,
                        name: display_list.name,
                        display_name: display_list.display_name,
                        nationality_name: display_list.nationality_name,
                        occupation_name: display_list.occupation_name,
                        religion_name: display_list.religion_name,
                        report_user_list_status: display_list.report_user_list_status,
                        request_list_id: display_list.request_list_id,
                        request_status: display_list.request_status,
                        selfie_status: display_list.selfie_status,
                        shortlist_status: display_list.shortlist_status,
                        state_name: display_list.state_name,
                        study_name: display_list.study_name,
                        unique_id: display_list.unique_id,
                        user_id: display_list.user_id,
                        weight: display_list.weight,
                        weight_unit: display_list.weight_unit,
                        photos_slider_status: display_list.photos_slider_status,
                        smoke: display_list.smoke,
                        drink: display_list.drink,
                        diet: display_list.diet
                    })

                } else {
                    document.getElementById(`likes-${new_id}`).src = `${like}`
                    setdisplay({
                        about_me: display_list.about_me, age: display_list.age, country_name: display_list.country_name, gender: display_list.gender,
                        height: display_list.height,
                        height_unit: display_list.height_unit,
                        images: display_list.images,
                        like_status: '0',
                        locked_status: display_list.locked_status,
                        mother_tongue: display_list.mother_tongue,
                        name: display_list.name,
                        display_name: display_list.display_name,
                        nationality_name: display_list.nationality_name,
                        occupation_name: display_list.occupation_name,
                        religion_name: display_list.religion_name,
                        report_user_list_status: display_list.report_user_list_status,
                        request_list_id: display_list.request_list_id,
                        request_status: display_list.request_status,
                        selfie_status: display_list.selfie_status,
                        shortlist_status: display_list.shortlist_status,
                        state_name: display_list.state_name,
                        study_name: display_list.study_name,
                        unique_id: display_list.unique_id,
                        user_id: display_list.user_id,
                        weight: display_list.weight,
                        weight_unit: display_list.weight_unit,
                        photos_slider_status: display_list.photos_slider_status,
                        smoke: display_list.smoke,
                        drink: display_list.drink,
                        diet: display_list.diet
                    })

                }
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            }

        }).catch((error) => {
            console.log(error)
        })

    }
    const handlesshortlist = event => {
        //inside the functions
        if (profileview.subscription_status == 0) {
            setModalShow(true);
            return false;
        }
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        // console.log(new_id)

        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/shortlist`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                let l_items = value.profiles
                setValue({ 'profiles': l_items, 'path': value.path })
                setdisplay({ user_id: '' })

                let Imgsrc = document.getElementById(`shortlistImg-${new_id}`);
                if (Imgsrc) {
                    Imgsrc.src = res.data.message === 'Profile Shortlisted' ? `${shortlisted}` : `${shortlist}`;
                }

                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            }
        }).catch((error) => {
            console.log(error)
        })

    }

    const cardDisplay = event => {
        makePageTransparent();
        setcloseaction('true');
        document.getElementById('three-dots').style.display = 'block'
        let id = event.currentTarget.id.split('-')[1]
        const proFormData = new FormData();
        proFormData.append('user_id', local_storage.user_id);
        proFormData.append('profile_id', id)
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/profile_view`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: proFormData
        }).then((response) => {
            setTimeout(() => {
                setdisplay({
                    about_me: response.data.data.about_me,
                    age: response.data.data.age,
                    country_name: response.data.data.country_name,
                    gender: response.data.data.gender,
                    height: response.data.data.height,
                    height_unit: response.data.data.height_unit,
                    images: response.data.data.images,
                    like_status: response.data.data.like_status,
                    locked_status: response.data.data.locked_status,
                    mother_tongue: response.data.data.mother_tongue,
                    name: response.data.data.name,
                    nationality_name: response.data.data.nationality_name.length > 1 ? response.data.data.nationality_name[0] + ',' + response.data.data.nationality_name[1] : response.data.data.nationality_name[0],
                    occupation_name: response.data.data.occupation_name,
                    religion_name: response.data.data.religion_name,
                    report_user_list_status: response.data.data.report_user_list_status,
                    request_list_id: response.data.data.report_list_id,
                    request_status: response.data.data.request_status,
                    selfie_status: response.data.data.selfie_status,
                    shortlist_status: response.data.data.shortlist_status,
                    state_name: response.data.data.state_name,
                    study_name: response.data.data.study_name,
                    unique_id: response.data.data.unique_id,
                    user_id: response.data.data.user_id,
                    weight: response.data.data.weight,
                    weight_unit: response.data.data.weight_unit,
                    photos_slider_status: response.data.data.photos_slider_status,
                    drink: response.data.data.drink,
                    smoke: response.data.data.smoke,
                    diet: response.data.data.diet,
                    display_name: response.data.data.display_name
                })
                makePagenotTransparent();
            }, 1000);
        }).catch((error) => {
            console.log(error);
            setDisable(false);
            makePagenotTransparent();
        })
    }
    const handleopenshare = () => {
        urlShortner()
        setTimeout(() => {
            setshare(true)
        }, 1000);

    }
    const handlecloseshare = () => {
        setshare(false)
    }
    let list_items = value.profiles.map((item) =>
        <div key={item.user_id} id={`${item.user_id}`}>
            <div className="card-button">
                <div className="keen-slider__slide mp number-slide1"
                    style={{
                        backgroundImage: `url(${item.selfie_status == '1' && item.photos_slider_status == '1' ? value.path + '/' + item.image : item.gender == '2' ? female : male})`,
                        height: '80vh',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                    }}>
                    
                    <div className="image-block">
                        <div className="slider-bottom d-flex flex-column justify-content-end" >
                            <div>
                                <div className="slider_toph">
                                    <h5>{item.name}, {item.age}
                                        
                                            <button className="action-buttons info-res" onClick={cardDisplay} id={`info-${item.user_id}`}>
                                                <img src={info} />
                                            </button>
                                    </h5>
                                    <h5 style={{ fontSize: 16 }}>
                                        <img src={map} className="px-1" />
                                        {item.state_name != '' ? item.state_name + ',' : ''} {item.country_name}.
                                    </h5>
                                </div>


                                <div className="d-flex slider_icons">
                                    <div>
                                        <OverlayTrigger
                                            overlay={<Tooltip style={{ backgroundColor: "#fff", borderRadius: "5px" }}>{"Skip"}</Tooltip>}>
                                            <button className="action-buttons btn-safari mx-2" id={`skip-${item.user_id}`} onClick={handleskip}><img src={skip} data-bs-toggle="tooltip" data-bs-placement="top" title="on top" /></button>
                                        </OverlayTrigger>
                                    </div>

                                    <div>
                                        <OverlayTrigger overlay={<Tooltip style={{ backgroundColor: "#fff", borderRadius: "5px" }}>{"Interest"}</Tooltip>}>
                                            <button className="action-buttons btn-safari mx-2" id={`interest-${item.user_id}`}
                                                onClick={handleinterest}>
                                                <img id={`interestImg-${item.user_id}`} src={item.request_status == '0' ? interested : interest} />
                                            </button>
                                        </OverlayTrigger>
                                    </div>

                                    <div>
                                        <OverlayTrigger overlay={<Tooltip style={{ backgroundColor: "#fff", borderRadius: "5px" }}>{"Like"}</Tooltip>}>
                                            <button className="action-buttons btn-safari mx-2" id={`like-${item.user_id}`} onClick={handlelike}><img id={`likes-${item.user_id}`} src={item.like_status == '0' ? like : liked} /></button>
                                        </OverlayTrigger>
                                    </div>

                                    <div>
                                        <OverlayTrigger overlay={<Tooltip style={{ backgroundColor: "#fff", borderRadius: "5px" }}>{"Shortlist"}</Tooltip>}>
                                            <button className="action-buttons btn-safari mx-2" id={`shortlist-${item.user_id}`} onClick={handlesshortlist}>
                                                <img id={`shortlistImg-${item.user_id}`}
                                                    src={item.shortlist_status === '0' ? shortlist : shortlisted} />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    const [linkToCopy, setLinkToCopy] = useState('');

    useEffect(() => {
        if (display_list && display_list.user_id) {
            const newLink = `${proUrl}/${local_storage.user_id}/${display_list.user_id}`;
            setLinkToCopy(newLink);
        }
    }, [display_list]);

    const [shorturl, setshorturl] = useState('')
    const urlShortner = async () => {
        let longurl = `${proUrl}/${local_storage.user_id}/${display_list.user_id}`
        setshorturl(longurl)
    }
    const handleClose1 = (event) => {
        event.preventDefault(); // Prevent the default link behavior (e.g., navigating to a new page)

    }
    const copyLink = () => {
        clipboardCopy(linkToCopy)
            .then(() => {
                Toastify({
                    text: "Link copied",
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            })
            .catch((error) => {
                console.error('Error copying link:', error);
            });
    };
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <PaymentModel
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

            <div id="overlay">
                <div className='AddloadingImage' >
                    <LottieLoader3 />
                </div>
            </div>

            <div className="d-lg-flex d-block">
                {shareprofile && shorturl != '' &&
                    <div className="modal modal_blur" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header p-0 ">
                                    <h5></h5>
                                    <h5 className="modal-title">Share Profile</h5>
                                    <button type="button" className="close share_close" onClick={handlecloseshare}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="text-center mb-3 mt-3">
                                    <WhatsappShareButton url={shorturl}>
                                        <WhatsappIcon size={32} round={true} className="mr-3"></WhatsappIcon>
                                    </WhatsappShareButton>&nbsp;
                                    <FacebookShareButton url={shorturl}>
                                        <FacebookIcon size={32} round={true} ></FacebookIcon>
                                    </FacebookShareButton>&nbsp;
                                    <OverlayTrigger overlay={<Tooltip>{"Copy link"}</Tooltip>}>
                                        <i className="fa fa-copy" onClick={copyLink} ></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="col-3 page_left" style={{ width: 'auto' }}>
                    <Sidemenu />
                </div>

                <div className="col-lg-9 col-12 d-flex flex-column match-request-page page_right">
                    <div className="d-flex flex-row bg-white top-bar shadow">
            <img src={img} className="w-50 site_logo d-block m-auto inner-logo"/>
                        <div className="menu-nav">
                            <div className="dropdown-container" tabIndex="-1">
                                <div className="three-dots display_matches" id="three-dots" style={{ display: 'none' }}></div>
                                <div className="dropdown">
                                    <a onClick={handleopenshare}><div>Share this profile</div></a>
                                    <a href="#" onClick={handleClose1}><div className="cancel">Close</div></a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="d-flex p-0 m-0">
                        <div className="user_info col-12 mt-4 mb-3">
                            {lottie ? <div className="data_status mt-5"><LottieLoader1 /> <p className="text-center mb-5"> NO MATCHES FOUND, EDIT THE PREFERENCES</p>
                                <Button onClick={() => navigate('/lookingfor')} className="m-auto d-block up-btn">Edit Preferences</Button>
                            </div> : ''}
                            {value.profiles.length > 0 && (
                                <div className="navigation-wrapper">
                                    <div ref={sliderRef} className="keen-slider matched-profile">
                                        <ul className="keen-slider__slides">
                                            {list_items.map((item, index) => (
                                                <li key={index} className="keen-slider__slide">
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    {/* Arrows for navigation */}
                                    {loaded && instanceRef.current && (
                                        <>
                                            <Arrow
                                                left
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    instanceRef.current?.prev(); // Move to the previous slide
                                                }}
                                                disabled={currentSlide === 0} // Disable left arrow if at the first slide
                                            />

                                            {!(currentSlide >= (instanceRef?.current?.track?.details?.slides?.length / 2) - 1) &&
                                                <Arrow
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        instanceRef.current?.next(); // Move to the next slide
                                                    }}
                                                    disabled={
                                                        currentSlide * 2 >= instanceRef.current.track.details.slides.length - 2 ||
                                                        value.profiles.length === 0
                                                    }
                                                />}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                        {display_list.user_id !== '' ?
                            <div id={`item-${display_list.user_id}`}>
                                {closeaction == 'true' ?
                                    <Card className="right-card profile_div" id="right-card" 
                                    >
                                        {display_list.images.length > 1
                                            ?
                                            <div style={{ backgroundColor: '#c4c4c4' }}>
                                                <span style={{ visibility: 'hidden' }}> aa </span>
                                                <span className="close_button float-end px-2" onClick={close} >
                                                    <img src={deleteicon} className="close-button" alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                                                </span>
                                                <div>
                                                    <Carousel>
                                                        {display_list.images.map((particular_image, imageindex) =>
                                                            <Carousel.Item key={imageindex} style={{ width: '100%', height: '300px', overflow: 'hidden', borderRadius: '0px', backgroundColor: '#c4c4c4' }}>

                                                                <Card.Img variant="top" src={`${display_list.selfie_status == '1' ? value.path + '/' + display_list.images[imageindex].image : display_list.gender == '1' ? male1 : female1}`} style={{ width: '100%', height: '100%', objectFit: 'contain', top: '0' }} />
                                                            </Carousel.Item>
                                                        )}
                                                    </Carousel>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ color: "#E6368B", backgroundColor: '#c4c4c4' }}>
                                                <span className="close_button float-end px-2" onClick={close} >
                                                    <img src={deleteicon} className="close-button" alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                                                </span>
                                                <Card.Img variant="top" src={`${display_list.selfie_status == '1' && display_list.images.length == '1' ? value.path + '/' + display_list.images[0].image : display_list.gender == '1' ? male1 : female1}`} style={{ width: '100%', height: '300px', top: '0', backgroundColor: '#c4c4c4', objectFit: 'contain' }} />
                                            </div>
                                        }
                                        <Card.Body>
                                            <Card.Title><p style={{ fontWeight: 500 }}>{display_list.display_name}, {display_list.age}, {display_list.gender == 1 ? "Male" : "Female"} <span style={{ color: '#E6368B' }}>(#{display_list.unique_id})</span></p>
                                                <p><img src={map} className="px-1" />{display_list.state_name ? `${display_list.state_name},` : ''} {display_list.country_name}.</p>
                                                {display_list.request_status == '4' ? <span style={{ color: '#E6368B' }}>{display_list.display_name} sent you a request</span> : ''}

                                                <p style={{ fontWeight: 500 }} className="mt-2">About me</p>
                                                <p>{display_list.about_me}</p>
                                            </Card.Title>

                                            <Card.Text>
                                                <div className="profile_data">
                                                    <div><span>Languages</span></div>
                                                    <div><span>{display_list.mother_tongue}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Nationality</span></div>
                                                    <div><span>{display_list.nationality_name}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Country</span></div>
                                                    <div><span>{display_list.country_name}</span></div>
                                                </div>
                                                {display_list.state_name != '' ? <div className="profile_data">
                                                    <div><span>State</span></div>
                                                    <div><span>{display_list.state_name}</span></div>
                                                </div> : ''}
                                                <div className="profile_data">
                                                    <div><span>Occupation</span></div>
                                                    <div><span>{display_list.occupation_name}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Height</span></div>
                                                    <div><span>{display_list.height} {display_list.height_unit}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Weight</span></div>
                                                    <div><span>{display_list.weight}</span></div>
                                                </div>
                                                {display_list.study_name &&
                                                    <div className="profile_data">
                                                        <div><span>Education</span></div>
                                                        <div><span>{display_list.study_name}</span></div>
                                                    </div>}
                                                <div className="profile_data">
                                                    <div><span>Religion</span></div>
                                                    <div><span>{display_list.religion_name}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Smoke</span></div>
                                                    <div><span>{display_list.smoke}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Drink</span></div>
                                                    <div><span>{display_list.drink}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Diet</span></div>
                                                    <div><span>{display_list.diet}</span></div>
                                                </div>
                                                <hr className="m-0 p-0" style={{ color: "#E6368B", backgroundColor: "#E6368B" }}></hr>
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="m-auto">
                                            <OverlayTrigger overlay={<Tooltip>{"Interest"}</Tooltip>}>
                                                <button className="action-buttons" id={`dinterest-${display_list.user_id}`} onClick={handleinterest}><img src={display_list.request_status == '0' ? interested : interest} className="mx-2" /></button>
                                            </OverlayTrigger>

                                            <OverlayTrigger overlay={<Tooltip>{"Like"}</Tooltip>}>
                                                <button className="action-buttons" id={`dlike-${display_list.user_id}`} onClick={handlelike}><img id={`dlikes-${display_list.user_id}`} src={display_list.like_status == '0' ? like : liked} className="mx-2" /></button>
                                            </OverlayTrigger>

                                            <OverlayTrigger overlay={<Tooltip>{"Shortlist"}</Tooltip>}>
                                                <button className="action-buttons" id={`dshortlist-${display_list.user_id}`} onClick={handlesshortlist}><img src={shortlist1} className="mx-2" /></button>
                                            </OverlayTrigger>

                                        </Card.Footer>
                                    </Card> : ""}
                            </div> : ''}
                    </div>

                </div>

            </div>
        </>
    )
}
// Arrow component for navigation
function Arrow({ left, onClick, disabled }) {
    const disabledClass = disabled ? " arrow--disabled" : "";
    return (
        <svg
            onClick={onClick}
            className={`arrow ${left ? "arrow--left" : "arrow--right"}${disabledClass}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            {left ? (
                <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            ) : (
                <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
            )}
        </svg>
    );
}

export default Matchedprofiles;
