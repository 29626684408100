import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Card } from 'react-bootstrap';
import decline from '../../assets/images/endcall.svg'
import accept from '../../assets/images/accept.svg'

const Testpage = () => {
    const [show, setShow] = useState(true);

    return (
        <Modal show={show} animation={false} >
            <Modal.Body>
                <Card className="payment-bottom-card mb-3 text-center">
                    <Card.Body>
                        <p>Get access to chat , calls and video calls</p>
                    </Card.Body>
                    <Card.Footer className='d-flex justify-content-around'>
                        <button className='modal_btns'><img src={accept} style={{ width: '50px' }} /></button>
                        <button className='modal_btns'><img src={decline} style={{ width: '50px' }} /></button>
                    </Card.Footer>
                </Card>
            </Modal.Body>

        </Modal>
    )
}

export default Testpage