import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown, Form, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from "axios";
import './selfie.css';
import Webcam from "react-webcam";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import LottieLoader from '../lottie/lottieloader';
import selfie from '../../assets/images/selfie_cam.png';
import icon from "../../assets/images/lock_icon.png";
import imageCompression from 'browser-image-compression'; // Import the compression library

function Selfie() {
  const [cameraAvailable, setCameraAvailable] = useState(false); // To detect camera availability
  const [otherlogin, setotherlogin] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState('');
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const location = useLocation();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const webcamRef = React.useRef(null);
  const local_storage = JSON.parse(localStorage.getItem("userDetails"));

  const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user"
  };

  // Detect if the camera is available
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        const videoDevices = devices.filter(device => device.kind === "videoinput");
        if (videoDevices.length > 0) {
          setCameraAvailable(true);
        } else {
          setCameraAvailable(false);
        }
      })
      .catch(err => {
        console.error("Error detecting camera: ", err);
        setCameraAvailable(false);
      });
  }, []);

  // Capture image from webcam
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  }, [webcamRef]);

  // Handle image file preview
  // Handle image file preview and set the uploaded image as the preview
  const showPreviewOne = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const maxSize = 3 * 1024 * 1024; // 3MB in bytes
  
      // Check if file size exceeds 3MB
      if (file.size > maxSize) {
        Toastify({
          text: "File size exceeds the 3MB limit.",
          duration: 3000,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)",
        }).showToast();
  
        // Reset the file input
        e.target.value = "";
        return;
      }
  
      // Compress and preview the image
      compressImage(file);
    }
  };
  
  const compressImage = async (file) => {
    try {
      const options = {
        maxSizeMB: 1, // max file size in MB
        maxWidthOrHeight: 800, // max width/height
        useWebWorker: true,
      };
  
      const compressedFile = await imageCompression(file, options);
      const compressedImageUrl = URL.createObjectURL(compressedFile);
      setImage(compressedImageUrl);
    } catch (error) {
      console.error("Error compressing image", error);
    }
  };
  
  

  const onSelfieSubmit = (data) => {
    setDisable(true);
  
    let blobObject;
  
    if (image.startsWith("data:image")) {
      // If the image is from the webcam (data URL), convert it to a file
      blobObject = dataURLtoFile(image, "image.png");
    } else {
      // If the image is from the file upload, fetch the file directly
      fetch(image)
        .then(res => res.blob())
        .then(blob => {
          blobObject = new File([blob], "image.png", { type: blob.type });
          submitSelfieData(blobObject);
        })
        .catch((e) => {
          console.error("Error processing uploaded image:", e);
          setDisable(false);
        });
      return;
    }
  
    // If the image is from webcam, submit the data directly
    submitSelfieData(blobObject);
  };
  
  const submitSelfieData = (blobObject) => {
    const selfieData = new FormData();
    // selfieData.append('user_id', location.state.user_id);
    let key;
    if (location.state !== null) {
      selfieData.append("user_id", location.state.user_id);
      key = location.state.api_key;
    } else {
      selfieData.append("user_id", local_storage.user_id);
      key = local_storage.api_key;
    }
    selfieData.append('image', blobObject);
  
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/selfie_verification`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': key,
      },
      data: selfieData
    }).then((res) => {
      if (res.data.status === 1) {
        //localStorage.setItem("userDetails", JSON.stringify(location.state));
        setTimeout(() => {
          navigate('/selfieverification', { state: location.state });
        }, 3000);
        Toastify({
          text: res.data.message,
          duration: 2000,
          close: true,
          gravity: "top",
          position: "right",
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
        }).showToast();
      } else {
        setDisable(false);
        Toastify({
          text: res.data.message,
          duration: 2000,
          close: true,
          gravity: "top",
          position: "right",
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
        }).showToast();
      }
    }).catch((e) => {
      console.log(e.response, 'res');
      setDisable(false);
      if (e.message === 'Request failed with status code 401') {
        setotherlogin(true);
      }
    });
  };
  
  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  

  const handleSelfie = (e) => {
    setShow(true);
  };

  const capture_func = (e) => {
    e.preventDefault();
    capture();
  };

  return (
    <div className="d-lg-flex d-block">
      <Modal show={otherlogin} size="lg" animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className="text-center">Sorry! Someone has logged in with your account. Please check and come back.</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => navigate('/Login')} className="up-btn">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      {disable ? <div className='AddPetloadingImage'><LottieLoader /></div> : ''}

      <div className="col-6 selfiePic"></div>

      <div className="col-lg-6 col-12 mt-5 content_login">
        <Button variant="danger" onClick={() => navigate("/Hobbies", { state: location.state })} className="back_button mt-3"><i className="fa fa-arrow-left">&nbsp; Back</i></Button>
        <div className="text-center">
          <img src={icon} alt="icon" />
          <h4 className="mt-3">Selfie Verification</h4>
        </div>
        <Form className="text-center mt-3" onSubmit={handleSubmit(onSelfieSubmit)}>
          {show ? '' : <img src={selfie} height="300px" alt="selfie" />}

          {show ? (
            <div>
              <div className="webcam-img">
                {image === '' ? (
                  <Modal show={show} fullscreen="xxl-down" onHide={() => setShow(false)}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className="d-flex align-items-center justify-content-center">
                      {cameraAvailable ? (
                        <Webcam
                          audio={false}
                          height={470}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          width="100%"
                          style={{ transform: 'scaleX(-1)' }} // Only apply flip to webcam
                        />
                      ) : (
                        <div className="custom-file-input-wrapper text-center">
                          <h6>No camera detected. Please upload a selfie:</h6>
                          <label className="custom-file-input-btn mt-3" htmlFor="fileUpload">
                            Choose File
                          </label><br></br>
                          <span>Note: File size should not exceed 3MB</span>
                          <input type="file" id="fileUpload" accept="image/*" onChange={showPreviewOne} style={{ display: 'none' }} />
                          {image && <div className="custom-file-input-text">Selected: {image.name}</div>}
                        </div>

                      )}
                    </Modal.Body>
                    {cameraAvailable && (
                      <Modal.Footer>
                        <button onClick={capture_func} className="webcam-btn btn btn-danger">Capture</button>
                      </Modal.Footer>
                    )}
                  </Modal>
                ) : (
                  // If the image is from the file upload, do not flip it
                  <img src={image} alt="Uploaded or Captured" style={cameraAvailable ? { transform: 'scaleX(-1)' } : {}} />
                )}
              </div>
              {image !== '' && (
                <div>
                  <button onClick={(e) => { e.preventDefault(); setImage(''); }} className="webcam-btn btn btn-danger mt-3">Retake Image</button>
                </div>
              )}
            </div>


          ) : ''}

          <div>
            {image !== '' ? (
              <Button variant="dark" type="submit" className="d-block m-auto mt-3 w-50 btn_disabled">SUBMIT</Button>
            ) : (
              <Button variant="dark" id="take-selfie" onClick={handleSelfie} className="m-auto mt-3 w-50 btn_disabled">TAKE SELFIE</Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Selfie;
