import React,{useState} from "react";
import icon from "../../assets/images/lock_icon.png";
import { Navbar, Nav, Container, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import { useForm } from "react-hook-form";
import LottieLoader from '../lottie/lottieloader';

function Forgotpassword() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [disable, setDisable] = useState(false);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const Auth = process.env.REACT_APP_USER_TOKEN;
    const navigate = useNavigate();
    const location = useLocation();
    const onQuestionSubmit = (data) => {
        setDisable(true)
        const regFormData = new FormData();
        regFormData.append('email_mobile', data.number);
        axios({
            method: 'POST',
            url: `${baseUrl}/Registration/forgot_password`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': Auth,
            },
            data: regFormData

        }).then((res) => {
            setDisable(false)
            if (res.data.status === 1) {
                setTimeout(() => {
                    navigate('/newpassword', { state: res.data.forgot_user_id })
                  }, 2000);
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            } else {
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            }
        }).catch((e) => {
            console.log(e, 'e')
            setDisable(false)
        })
    }

    return (
        <div className="d-flex">
             {disable ?<div className='AddPetloadingImage'><LottieLoader /></div> : '' }
            <div className="col-6 security_page"></div>
           
            <div className="col-6 content">
                <div className="p-2 text-center">
                    
                    <img src={icon}  />
                    <h5 className="mb-5 mt-2">Forgot Password</h5>
                    <div className="col-7 m-auto">
                        <Form onSubmit={handleSubmit(onQuestionSubmit)}>
                            <Form.Control
                                type="text"
                                placeholder="Email / Mobile Number"
                                {...register("number",{required:"true"})}
                                className="forgot_field"
                            />
                            <span className='error_msg'> {errors.number?.type==='required'&&"Please enter email/mobile number"}</span>
                            <Button variant="primary" type="submit" className="w-50 login_button text-center mt-5">
                                SUBMIT
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Forgotpassword;
