import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

export default function PaymentModel(props) {
  const navigate = useNavigate();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{fontSize: '20px'}}>
        Unlock Premium Features!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
        Upgrade now to start building meaningful connections! Show your interest and connect more effectively by upgrading to a Premium plan.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{navigate('/payment')}} style={{backgroundColor: '#E6368B', border: 'none'}}>Upgrade Now</Button>
      </Modal.Footer>
    </Modal>
  );
}