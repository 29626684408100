import React from 'react'
import { Card } from 'react-bootstrap';
import Sidemenu from "../sidebar/sidemenu";
import Fail from "../../assets/images/error-img.gif"
import img from '../../assets/images/logo.svg'


function Paymentfail() {
  return (
    <div className="d-lg-flex d-block">
    <div className="col-3 page_left" style={{ width: 'auto' }}>
      <Sidemenu />
    </div>
    <div className="col-9 d-flex flex-column payment-page page_right">
    <div className="d-flex flex-row bg-white top-bar shadow">
        <img src={img} className="w-50 site_logo d-block m-auto inner-logo"/>
        </div>
      <Card className='mt-5 col-6 pay_success' >
      <img src={Fail} style={{width:'100%'}}/>
        <h4><b>Payment Failed</b></h4>
      </Card>
    </div>
  </div>

  )
}

export default Paymentfail