import React from "react";
import img1 from "../../assets/images/logo.svg";
import { Link } from 'react-router-dom';
import facebook from "../../assets/images/Facebook.svg"
import instagram from "../../assets/images/Instagram.svg"

function footer() {
    const footerUrl = process.env.REACT_APP_FOOTER_URL;

    return (
        <div className="footer">
            <div className="foot_1">
                <div>
                    <img src={img1} className="footer_logo"/>
                </div>
                <div>
                    <ul className="footer_bottom">
                        <Link to='/' className="footer_links" rel="noopener noreferrer">Home</Link>
                        <Link to='/aboutpage' className="footer_links" rel="noopener noreferrer">About Us</Link>
                        <Link to='/membership' className="footer_links" rel="noopener noreferrer">Membership</Link>
                        <a href={`${footerUrl}/terms_and_conditions`} target="_blank" className="pt_test footer_links">Terms and Conditions</a>
                        <a href={`${footerUrl}/privacy_policy`} target="_blank" className="pt_test footer_links">Privacy Policy</a>
                    </ul>
                </div>
                {/* <div>
                    <a href="https://www.facebook.com/" target="_blank"><img src={facebook}></img></a>
                    <a href="https://www.instagram.com/" target="_blank"><img src={instagram}></img></a>
                </div> */}
            </div>
            <div className="foot_2">
                <p className="text-center text-white">© Copyright {new Date().getFullYear()} Redated. All Rights Reserved.</p>
            </div>
        </div>
    )
}

export default footer;