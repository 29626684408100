import { Navigate, useLocation, useNavigate } from "react-router-dom";

const Private = ({ child }) => {
    const Url = process.env.REACT_APP_LIVE_URL_WEB
    const location = useLocation();
    const history = useNavigate();
    const Auth = localStorage.getItem('gAuth');
    let path = location.pathname;
    let fullpath = `${Url}` + path;
    if(Auth==null){
        localStorage.setItem('profileview',fullpath);
        history('/Login');
    }
    return Auth == "true" ? child : <Navigate to="/" />
}

export default Private