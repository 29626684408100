import { initializeApp } from "firebase/app";
//import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";

const firebaseConfig = {
  apiKey: "AIzaSyBhEalETYDe81U5PbPaBOQSeQgO0exImkY",
  authDomain: "mm4u-1649232215351.firebaseapp.com",
  databaseURL: "https://mm4u-1649232215351-default-rtdb.firebaseio.com",
  projectId: "mm4u-1649232215351",
  storageBucket: "mm4u-1649232215351.appspot.com",
  messagingSenderId: "770511414431",
  appId: "1:770511414431:web:737cfaaea80ec47f43eeaa",
  measurementId: "G-H2T8HCEJ7T"
};

const app = initializeApp(firebaseConfig);
//const messaging = getMessaging(app);

// onMessage(messaging, (payload) => {
//   console.log("Message received. ", payload);
//   Toastify({
//     text: `${payload.notification.body}`,
//     duration: 5000,
//         newWindow: true,
//         close: true,
//         gravity: "bottom", 
//         position: "right", 
//         stopOnFocus: true, 
        
//         style: {
//             background: "#ffffff",
//             color: "#000000",
//             borderRadius:'2rem',
//           },
//   }).showToast();
// });

// onMessage(messaging, (payload) => {
//   console.log("Message received. ", payload);
//   Toastify({
//     text: `${payload.notification.body}`,
//     duration: 5000,
//         newWindow: true,
//         close: true,
//         gravity: "bottom", 
//         position: "right", 
//         stopOnFocus: true, 
        
//         style: {
//             background: "#ffffff",
//             color: "#000000",
//             borderRadius:'2rem',
//           },
//   }).showToast();
// });

// export function requestPermission() {
//   console.log("Requesting permission...");
//   Notification.requestPermission().then((permission) => {
//     if (permission === "granted") {
//       console.log("Notification permission granted.");
//       getToken(messaging, {
//         vapidKey: "BEhptSD1p6l5_LpnB-6PerD9FovS0yTz5cy1Ng_GOZNDtKMtwq9R6psbg_TTnKIRHbJLWKRHkNeGuIzdvgSZVo8",
//       })
//         .then((currentToken) => {
//           if (currentToken) {
//             console.log("Current FCM token: ", currentToken);
//             console.log("Current FCM token: ", currentToken);
//             localStorage.setItem('fcmToken', currentToken)
//             // Do something with the FCM token
//           } else {
//             console.log("Could not get token");
//           }
//         })
//         .catch((err) => {
//           console.error("Error getting FCM token:", err);
//         });
//     } else {
//       console.log("Do not have permission for notifications.");
//     }
//   });
// }

//requestPermission();

// export const onMessageListener = () =>

// new Promise((resolve) => {

// onMessage(messaging, (payload) => {

// resolve(payload);

// });

// });
